import {
  CREDITCARD_REQUEST,
  CREDITCARD_ERROR,
  CREDITCARD_GET_ALL,
  CREDITCARD_GET_ALL_SUCCESS,
  CREDITCARD_GET_GROUP,
  CREDITCARD_GET_GROUP_SUCCESS,
  CREDITCARD_GET_PURCHASED,
  CREDITCARD_GET_PURCHASED_SUCCESS,
  CREDITCARD_GET_ONE,
  CREDITCARD_GET_ONE_SUCCESS,
  CREDITCARD_PURCHASE,
  CREDITCARD_PURCHASE_SUCCESS,
  CREDITCARD_NEW_UPDATE,
  CREDITCARD_NEW_RESET,
  CREDITCARD_NEW_STORE,
  CREDITCARD_NEW_STORE_SUCCESS,
  CREDITCARD_USE_CREDITS,
  CREDITCARD_USE_CREDITS_SUCCESS,
  CREDITCARD_UPDATE,
  CREDITCARD_UPDATE_SUCCESS,
  CREDITCARD_GET_ALL_OF_USER_ON_LOCATION,
  CREDITCARD_GET_ALL_OF_USER_ON_LOCATION_SUCCESS
} from '../actions/creditcard'

import { DateTime } from 'luxon'
import api from '@/utils/api'
import config from '@/utils/config'

const state = {
  all: [],
  groupCards: [],
  selected: {},
  new:
    localStorage.getItem('newCreditcard') !== null
      ? {
          details: {
            events: []
          },
          ...JSON.parse(localStorage.getItem('newCreditcard'))
        }
      : {
          cost: undefined,
          type: undefined,
          details: {
            events: [],
            groupId: undefined,
            times_to_use: undefined,
            validity_period: undefined
          },
          source: undefined
        },
  activeCardOnLocation: {},
  loading: false,
  error: undefined
}

const getters = {
  newCreditcard: (state) => state.new
}

const actions = {
  [CREDITCARD_GET_ALL]: async ({ commit }, options = {}) => {
    const { groupId, ownerId } = options
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_GET_ALL,
      payload: { groupId, ownerId }
    })
    try {
      const resp = await api.get('creditcard', {
        params: {
          filter: {
            where: {
              and: [
                { ...(groupId ? { groupId } : {}) },
                { ...(ownerId ? { owner_id: ownerId } : {}) }
              ]
            }
          }
        }
      })
      if (resp && resp.status === 200) {
        commit(CREDITCARD_GET_ALL_SUCCESS, resp)
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
    }
  },
  [CREDITCARD_GET_ONE]: async ({ commit }, { creditcardId }) => {
    commit(CREDITCARD_REQUEST, { action: CREDITCARD_GET_ONE, creditcardId })
    try {
      const resp = await api.get(`creditcard/${creditcardId}`)
      if (resp && resp.status === 200) {
        commit(CREDITCARD_GET_ONE_SUCCESS, resp)
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
    }
  },
  [CREDITCARD_PURCHASE]: async (
    { commit },
    { cardId, participantId, paymentMethod, issuer, signUpForEvents, returnUrl }
  ) => {
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_PURCHASE,
      body: {
        cardId,
        participantId,
        paymentMethod,
        issuer,
        returnUrl
      }
    })
    try {
      const resp = await api.post(`creditcard/${cardId}/purchase`, {
        participantId,
        confirmationUrl: `${config.appUrl}${
          returnUrl ? returnUrl : '/cards/confirmation'
        }`,
        paymentMethod,
        issuer,
        signUpForEvents
      })
      if (resp && resp.status === 200 && resp.data && resp.data.redirectUrl) {
        commit(CREDITCARD_PURCHASE_SUCCESS)
        return resp.data.redirectUrl
      } else {
        commit(
          CREDITCARD_ERROR,
          `Something went wrong while purchasing creditcard`
        )
        return null
      }
    } catch (err) {
      commit(
        CREDITCARD_ERROR,
        `Something went wrong while purchasing creditcard: ${err}`
      )
      return null
    }
  },
  [CREDITCARD_NEW_STORE]: async ({ commit }, newCard) => {
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_NEW_STORE,
      body: newCard
    })
    try {
      const resp = await api.post(`creditcard`, newCard)
      if (resp && resp.status === 200) {
        commit(CREDITCARD_NEW_STORE_SUCCESS, resp)
        commit(CREDITCARD_NEW_RESET)
        return true
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
      return false
    }
  },
  [CREDITCARD_USE_CREDITS]: async ({ commit }, { creditcardId }) => {
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_USE_CREDITS,
      body: creditcardId
    })
    try {
      const resp = await api.post(`creditcard/${creditcardId}/use`)
      if (resp && resp.status === 200) {
        commit(CREDITCARD_USE_CREDITS_SUCCESS)
        return true
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
      return false
    }
  },
  [CREDITCARD_GET_GROUP]: async ({ commit }, { groupId }) => {
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_GET_GROUP,
      body: groupId
    })
    try {
      const resp = await api.get(`creditcard/group/${groupId}`)
      if (resp && resp.status === 200) {
        commit(CREDITCARD_GET_GROUP_SUCCESS, resp.data)
        return true
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
      return false
    }
  },
  [CREDITCARD_GET_PURCHASED]: async ({ commit }) => {
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_GET_PURCHASED
    })
    try {
      const resp = await api.get(`creditcard/purchased`)
      if (resp && resp.status === 200) {
        commit(CREDITCARD_GET_PURCHASED_SUCCESS, resp.data)
        return true
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
      return false
    }
  },
  [CREDITCARD_UPDATE]: async ({ commit }, card) => {
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_UPDATE,
      payload: card
    })
    try {
      const resp = await api.patch(`creditcard/${card.id}`, { ...card })
      if (resp && resp.status === 200) {
        commit(CREDITCARD_UPDATE_SUCCESS)
        return true
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
      return false
    }
  },
  [CREDITCARD_GET_ALL_OF_USER_ON_LOCATION]: async (
    { commit },
    { locationId }
  ) => {
    commit(CREDITCARD_REQUEST, {
      action: CREDITCARD_GET_ALL_OF_USER_ON_LOCATION,
      body: locationId
    })
    try {
      const resp = await api.get(
        `participant/creditcard/location/${locationId}`
      )
      if (resp && resp.status === 200) {
        commit(CREDITCARD_GET_ALL_OF_USER_ON_LOCATION_SUCCESS, resp.data)
        return true
      }
    } catch (err) {
      commit(CREDITCARD_ERROR, `Something went wrong: ${err}`)
      return false
    }
  }
}

const mutations = {
  [CREDITCARD_REQUEST]: (state) => {
    state.loading = true
    state.error = undefined
  },
  [CREDITCARD_ERROR]: (state, error) => {
    state.loading = false
    state.error = error
  },
  [CREDITCARD_GET_ALL_SUCCESS]: (state, resp) => {
    state.loading = false
    state.all = resp.data
  },
  [CREDITCARD_GET_ONE_SUCCESS]: (state, resp) => {
    state.loading = false
    state.selected = {
      ...resp.data,
      ...(resp.data.purchased_by
        ? {
            purchased_by: resp.data.purchased_by.map((item) => ({
              ...item,
              avatarUrl: item.avatarUrl
                ? config.baseUrl + item.avatarUrl
                : null,
              purchased_at: DateTime.fromISO(item.purchased_at).toLocaleString(
                DateTime.DATE_MED
              )
            }))
          }
        : {}),
      ...(resp.data.purchased_at
        ? {
            purchased_at: DateTime.fromISO(
              resp.data.purchased_at
            ).toLocaleString(DateTime.DATE_MED)
          }
        : {})
    }
  },
  [CREDITCARD_PURCHASE_SUCCESS]: (state) => {
    state.loading = false
  },
  [CREDITCARD_NEW_UPDATE]: (state, card) => {
    state.new = {
      ...state.new,
      ...card
    }
    localStorage.setItem('newCreditcard', JSON.stringify(card))
  },
  [CREDITCARD_NEW_RESET]: (state) => {
    state.new = {
      cost: undefined,
      type: undefined,
      groupId: undefined,
      details: {
        events: [],
        times_to_use: undefined
      },
      source: undefined
    }
    localStorage.removeItem('newCreditcard')
  },
  [CREDITCARD_NEW_STORE_SUCCESS]: (state) => {
    state.loading = false
  },
  [CREDITCARD_USE_CREDITS_SUCCESS]: (state) => {
    state.loading = false
  },
  [CREDITCARD_GET_GROUP_SUCCESS]: (state, data) => {
    state.loading = false
    state.groupCards = data.filter((item) => item.active)
  },
  [CREDITCARD_GET_PURCHASED_SUCCESS]: (state, data) => {
    state.loading = false
    state.purchasedCards = data
  },
  [CREDITCARD_UPDATE_SUCCESS]: (state) => {
    state.loading = false
  },
  [CREDITCARD_GET_ALL_OF_USER_ON_LOCATION_SUCCESS]: (state, data) => {
    state.loading = false
    state.activeCardOnLocation = Array.isArray(data) ? data[0] : data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
