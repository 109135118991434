import { ifAuthenticated } from './guards'
const OrganizeActivity = () =>
  import(/* webpackChunkName: "organize" */ '@/views/organize/OrganizeActivity')
const OrganizeCosts = () =>
  import(/* webpackChunkName: "organize" */ '@/views/organize/OrganizeCosts')
const OrganizeEventType = () =>
  import(
    /* webpackChunkName: "organize" */ '@/views/organize/OrganizeEventType'
  )
const OrganizeInfo = () =>
  import(/* webpackChunkName: "organize" */ '@/views/organize/OrganizeInfo')
const OrganizeInvite = () =>
  import(/* webpackChunkName: "organize" */ '@/views/organize/OrganizeInvite')
const OrganizeLocation = () =>
  import(/* webpackChunkName: "organize" */ '@/views/organize/OrganizeLocation')
const OrganizeLocationDetails = () =>
  import(
    /* webpackChunkName: "organize" */ '@/views/organize/OrganizeLocationDetails'
  )
const OrganizeLocationList = () =>
  import(
    /* webpackChunkName: "organize" */ '@/views/organize/OrganizeLocationList'
  )
const OrganizeLocationMap = () =>
  import(
    /* webpackChunkName: "organize" */ '@/views/organize/OrganizeLocationMap'
  )
const OrganizeLocationOptions = () =>
  import(
    /* webpackChunkName: "organize" */ '@/views/organize/OrganizeLocationOptions'
  )
const OrganizeReady = () =>
  import(/* webpackChunkName: "organize" */ '@/views/organize/OrganizeReady')
const OrganizeWhen = () =>
  import(/* webpackChunkName: "organize" */ '@/views/organize/OrganizeWhen')
const AddEventOrCreditcard = () =>
  import(/* webpackChunkName: "organize" */ '@/views/AddEventOrCreditcard')

export default [
  {
    path: '/events/new/sport',
    alias: '/events/new/',
    name: 'OrganizeActivity',
    component: OrganizeActivity,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/type',
    name: 'OrganizeEventType',
    component: OrganizeEventType,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/date',
    name: 'OrganizeWhen',
    component: OrganizeWhen,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/organize-location-options',
    name: 'OrganizeLocationOptions',
    component: OrganizeLocationOptions,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/organize-location-list',
    name: 'OrganizeLocationList',
    component: OrganizeLocationList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/location',
    name: 'OrganizeLocation',
    component: OrganizeLocation,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/map',
    name: 'OrganizeLocationMap',
    component: OrganizeLocationMap,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/organize-location-details',
    name: 'OrganizeLocationDetails',
    component: OrganizeLocationDetails,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/info',
    name: 'OrganizeInfo',
    component: OrganizeInfo,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/invite',
    name: 'OrganizeInvite',
    component: OrganizeInvite,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/costs',
    name: 'OrganizeCosts',
    component: OrganizeCosts,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/new/ready',
    name: 'OrganizeReady',
    component: OrganizeReady,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/organize',
    name: 'OrganizeStart',
    component: AddEventOrCreditcard,
    beforeEnter: ifAuthenticated
  }
]
