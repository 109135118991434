export const PAYMENT_ADD_PAYMENT_NEEDED = 'PAYMENT_ADD_PAYMENT_NEEDED'
export const PAYMENT_ADD_REFUND_NEEDED = 'PAYMENT_ADD_REFUND_NEEDED'
export const PAYMENT_GET_METHODS = 'PAYMENT_GET_METHODS'
export const PAYMENT_GET_METHODS_ERROR = 'PAYMENT_GET_METHODS_ERROR'
export const PAYMENT_GET_METHODS_SUCCESS = 'PAYMENT_GET_METHODS_SUCCESS'
export const PAYMENT_GET_STATUS = 'PAYMENT_GET_STATUS'
export const PAYMENT_GET_STATUS_SUCCESS = 'PAYMENT_GET_STATUS_SUCCESS'
export const PAYMENT_GET_PAID_ACTIVITIES = 'PAYMENT_GET_PAID_ACTIVITIES'
export const PAYMENT_GET_PAID_ACTIVITIES_ERROR =
  'PAYMENT_GET_PAID_ACTIVITIES_ERROR'
export const PAYMENT_GET_PAID_ACTIVITIES_SUCCESS =
  'PAYMENT_GET_PAID_ACTIVITIES_SUCCESS'
export const PAYMENT_GET_REFUNDS = 'PAYMENT_GET_REFUNDS'
export const PAYMENT_GET_REFUNDS_SUCCESS = 'PAYMENT_GET_REFUNDS_SUCCESS'
export const PAYMENT_REMOVE_PAYMENT_NEEDED = 'PAYMENT_REMOVE_PAYMENT_NEEDED'
export const PAYMENT_REMOVE_REFUND_NEEDED = 'PAYMENT_REMOVE_REFUND_NEEDED'
export const PAYMENT_RESET_STATE = 'PAYMENT_RESET_STATE'
export const PAYMENT_SELECT_BANK = 'PAYMENT_SELECT_BANK'
export const PAYMENT_SELECT_METHOD = 'PAYMENT_SELECT_METHOD'
export const PAYMENT_SET_PARTICIPANT_IS_NEW = 'PAYMENT_SET_PARTICIPANT_IS_NEW'
export const PAYMENT_SET_TYPE = 'PAYMENT_SET_TYPE'
export const PAYMENT_GET_STATUS_BY_ID = 'PAYMENT_GET_STATUS_BY_ID'
export const PAYMENT_GET_STATUS_BY_ID_SUCCESS =
  'PAYMENT_GET_STATUS_BY_ID_SUCCESS'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
