import {
  PARTICIPANT_ACTIVITY_REQUEST,
  PARTICIPANT_ACTIVITY_SUCCESS,
  PARTICIPANT_ACTIVITY_ERROR,
  PARTICIPANT_ACTIVITY_CREATE,
  PARTICIPANT_ACTIVITY_FIND_ONE,
  PARTICIPANT_ACTIVITY_DELETE
} from '../actions/participant_activity'
import api from '@/utils/api'

const state = { status: '' }

const getters = {}

const actions = {
  async [PARTICIPANT_ACTIVITY_FIND_ONE]({ commit }, data) {
    commit(PARTICIPANT_ACTIVITY_REQUEST)
    try {
      return await api.get('participant_activity/findOne', {
        params: {
          filter: {
            where: {
              activityID: data.activityID,
              participantID: data.participantID
            }
          }
        }
      })
    } catch (e) {
      commit(PARTICIPANT_ACTIVITY_ERROR)
      return e
    }
  },
  async [PARTICIPANT_ACTIVITY_CREATE]({ commit }, item) {
    commit(PARTICIPANT_ACTIVITY_REQUEST)

    try {
      return await api.post('participant_activity', {
        activity_type_id: item.activityID,
        activityID: item.activityID, // DEPRECATED, have to remove all activityId's
        participantID: item.participantID
      })
    } catch (e) {
      commit(PARTICIPANT_ACTIVITY_ERROR)
      return false
    }
  },
  async [PARTICIPANT_ACTIVITY_DELETE]({ commit }, participantActivityID) {
    commit(PARTICIPANT_ACTIVITY_REQUEST)
    try {
      return await api.delete('participant_activity/' + participantActivityID)
    } catch (e) {
      commit(PARTICIPANT_ACTIVITY_ERROR)
      return false
    }
  }
}

const mutations = {
  [PARTICIPANT_ACTIVITY_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [PARTICIPANT_ACTIVITY_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [PARTICIPANT_ACTIVITY_ERROR]: (state) => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
