import {
  RESULT_ADD,
  RESULT_ADD_SUCCESS,
  RESULT_FIND,
  RESULT_FIND_SUCCESS,
  RESULT_FIND_ERROR,
  RESULT_RESET_STATE,
  RESULT_ERROR,
  RESULT_REQUEST
} from '../actions/result'
import api from '@/utils/api'
import store from '../index'

const state = {
  loading: false,
  error: null,
  addedResult: null,
  foundResult: null
}

const getters = {
  foundResult: (state) => state.foundResult
}

const actions = {
  [RESULT_ADD]: async ({ commit, dispatch }, { leagueId, dateId, points }) => {
    commit(RESULT_REQUEST)
    try {
      await dispatch(RESULT_FIND, { leagueId, dateId })
      const existingResult = store.getters.foundResult
      if (existingResult && Object.keys(existingResult).length) {
        const resp = await api.patch(`result/${existingResult.id}`, {
          points
        })
        if (resp.status === 200 && resp.data) {
          commit(RESULT_ADD_SUCCESS, resp.data)
        } else {
          commit(RESULT_ERROR, `Something went wrong here: ${resp.statusText}`)
        }
      } else {
        const resp = await api.post('result', {
          leagueID: leagueId,
          dateID: dateId,
          points
        })
        if (resp.status === 200 && resp.data) {
          commit(RESULT_ADD_SUCCESS, resp.data)
        } else {
          commit(RESULT_ERROR, `Something went wrong here: ${resp.statusText}`)
        }
      }
    } catch (err) {
      commit(RESULT_ERROR, `Something went wrong: ${err}`)
    }
  },
  [RESULT_FIND]: async ({ commit }, { leagueId, dateId }) => {
    commit(RESULT_REQUEST)
    try {
      const resp = await api.get('result/findOne', {
        params: {
          filter: {
            where: {
              and: [
                {
                  leagueID: leagueId
                },
                {
                  dateID: dateId
                }
              ]
            }
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(RESULT_FIND_SUCCESS, resp.data)
      } else {
        commit(
          RESULT_FIND_ERROR,
          `Something went wrong here: ${resp.statusText}`
        )
      }
    } catch (err) {
      commit(RESULT_FIND_ERROR, `Something went wrong: ${err}`)
    }
  }
}

const mutations = {
  [RESULT_REQUEST]: (state) => {
    state.loading = true
  },
  [RESULT_ERROR]: (state, errorMessage) => {
    state.loading = false
    state.error = errorMessage
  },
  [RESULT_ADD_SUCCESS]: (state, resp) => {
    state.loading = false
    state.addedResult = resp
  },
  [RESULT_FIND_SUCCESS]: (state, resp) => {
    state.loading = false
    state.foundResult = resp
  },
  [RESULT_FIND_ERROR]: (state, errorMessage) => {
    state.loading = false
    state.error = errorMessage
    state.foundResult = null
  },
  [RESULT_RESET_STATE]: (state) => {
    state.loading = false
    state.addedResult = null
    state.foundResult = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
