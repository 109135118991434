import store from '../store'

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
  } else {
    next({
      path: '/login',
      query: {
        redirectTo: to?.path || from?.path,
        ...(to?.query?.referral_uid
          ? { referral_uid: to.query.referral_uid }
          : {}),
        ...(from?.query?.referral_uid
          ? { referral_uid: from.query.referral_uid }
          : {})
      }
    })
  }
}

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
  } else {
    next('/')
  }
}
