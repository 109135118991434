export const EVENT_REQUEST = 'EVENT_REQUEST'
export const EVENT_ERROR = 'EVENT_ERROR'
export const EVENT_STATUS_EMPTY = 'EVENT_STATUS_EMPTY'
export const EVENT_BY_ID_LIST = 'EVENT_BY_ID_LIST'
export const EVENT_BY_ID_LIST_SUCCESS = 'EVENT_BY_ID_LIST_SUCCESS'
export const EVENT_SIGNED_UP_LIST = 'EVENT_SIGNED_UP_LIST'
export const EVENT_SIGNED_UP_LIST_SUCCESS = 'EVENT_SIGNED_UP_LIST_SUCCESS'
export const EVENT_OWNED_LIST = 'EVENT_OWNED_LIST'
export const EVENT_OWNED_LIST_SUCCESS = 'EVENT_OWNED_LIST_SUCCESS'
export const EVENT_CONVERT_LIST_ITEM = 'EVENT_CONVERT_LIST_ITEM'
export const EVENT_SET_FILTER = 'EVENT_SET_FILTER'
export const EVENT_SET_FILTER_SUCCESS = 'EVENT_SET_FILTER_SUCCESS'
export const EVENT_TYPE_SET_FILTER = 'EVENT_TYPE_SET_FILTER'
export const EVENT_SELECT = 'EVENT_SELECT'
export const EVENT_SELECT_SUCCESS = 'EVENT_SELECT_SUCCESS'
export const EVENT_DESELECT = 'EVENT_DESELECT'
export const EVENT_SET_LOCATION_CITY_LIST = 'EVENT_SET_LOCATION_CITY_LIST'
export const EVENT_ITEM_UPDATE = 'EVENT_ITEM_UPDATE'
export const EVENT_ITEM_SET_EVENT_TYPE = 'EVENT_ITEM_SET_EVENT_TYPE'
export const EVENT_ITEM_SET_ACTIVITY = 'EVENT_ITEM_SET_ACTIVITY'
export const EVENT_ITEM_SET_WHEN = 'EVENT_ITEM_SET_WHEN'
export const EVENT_ITEM_SET_LOCATION = 'EVENT_ITEM_SET_LOCATION'
export const EVENT_ITEM_SET_COMMERCIAL_LOCATION =
  'EVENT_ITEM_SET_COMMERCIAL_LOCATION'
export const EVENT_UPDATE_SET_LOCATION = 'EVENT_UPDATE_SET_LOCATION'
export const EVENT_ITEM_SET_INFO = 'EVENT_ITEM_SET_INFO'
export const EVENT_ITEM_SET_COSTS = 'EVENT_ITEM_SET_COSTS'
export const EVENT_CREATE = 'EVENT_CREATE'
export const EVENT_UPDATE = 'EVENT_UPDATE'
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS'
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS'
export const EVENT_CREATE_UPLOAD_SUCCESS = 'EVENT_CREATE_UPLOAD_SUCCESS'
export const EVENT_EDIT_UPLOAD_SUCCESS = 'EVENT_EDIT_UPLOAD_SUCCESS'
export const EVENT_VIEW = 'EVENT_VIEW'
export const EVENT_EDIT_SUCCESS = 'EVENT_EDIT_SUCCESS'
export const EVENT_EDIT_REQUEST_COMPLETED = 'EVENT_EDIT_REQUEST_COMPLETED'
export const EVENT_EDIT = 'EVENT_EDIT'
export const EVENT_VIEW_SUCCESS = 'EVENT_VIEW_SUCCESS'
export const EVENT_VIEW_REQUEST_COMPLETED = 'EVENT_VIEW_REQUEST_COMPLETED'
export const EVENT_DELETE = 'EVENT_DELETE'
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS'
export const EVENT_PARTICIPANT_ATTENDANCE = 'EVENT_PARTICIPANT_ATTENDANCE'
export const EVENT_PARTICIPANT_ATTENDANCE_SUCCESS =
  'EVENT_PARTICIPANT_ATTENDANCE_SUCCESS'
export const EVENT_ITEM_SET_GROUP = 'EVENT_ITEM_SET_GROUP'
export const EVENT_UPDATE_SINGLE = 'EVENT_UPDATE_SINGLE'
export const EVENT_UPDATE_SINGLE_SUCCESS = 'EVENT_UPDATE_SINGLE_SUCCESS'
export const EVENT_UPDATE_IMAGE = 'EVENT_UPDATE_IMAGE'
export const EVENT_UPDATE_IMAGE_SUCCESS = 'EVENT_UPDATE_IMAGE_SUCCESS'
export const EVENT_CREATE_RESET = 'EVENT_CREATE_RESET'
export const EVENT_ITEM_SET = 'EVENT_ITEM_SET'
export const EVENT_LOGGED_IN_USER_ATTENDANCE = 'EVENT_LOGGED_IN_USER_ATTENDANCE'
export const EVENT_LOGGED_IN_USER_ATTENDANCE_SUCCESS =
  'EVENT_LOGGED_IN_USER_ATTENDANCE_SUCCESS'
export const EVENT_CREATED_BY_PARTICIPANT = 'EVENT_CREATED_BY_PARTICIPANT'
export const EVENT_CREATED_BY_PARTICIPANT_SUCCESS =
  'EVENT_CREATED_BY_PARTICIPANT_SUCCESS'
export const EVENT_STORE_DATES_TO_UPDATE = 'EVENT_STORE_DATES_TO_UPDATE'
export const EVENT_GET_CREDITCARDS = 'EVENT_GET_CREDITCARDS'
export const EVENT_GET_CREDITCARDS_SUCCESS = 'EVENT_GET_CREDITCARDS_SUCCESS'
export const EVENT_UPDATE_ATTENDANCE = 'EVENT_UPDATE_ATTENDANCE'
export const EVENT_UPDATE_ATTENDANCE_SUCCESS = 'EVENT_UPDATE_ATTENDANCE_SUCCESS'
