const LandingPage = () =>
  import(/* webpackChunkName: "main" */ '@/views/LandingPage')

export default [
  {
    path: '/tennisbaan-huren',
    name: 'book-tennis',
    component: LandingPage,
    props: {
      title: 'Tennisbaan huren',
      showBookingLink: true
    }
  },
  {
    path: '/squashbaan-huren',
    name: 'book-squash',
    component: LandingPage,
    props: {
      title: 'Squashbaan huren',
      showBookingLink: true
    }
  },
  {
    path: '/padelbaan-huren',
    name: 'book-padel',
    component: LandingPage,
    props: {
      title: 'Padelbaan huren',
      showBookingLink: true
    }
  },
  {
    path: '/gymzaal-huren',
    name: 'book-gym',
    component: LandingPage,
    props: {
      title: 'Gymzaal huren',
      showBookingLink: true
    }
  },
  {
    path: '/sportzaal-huren',
    name: 'book-indoor',
    component: LandingPage,
    props: {
      title: 'Sportzaal huren',
      showBookingLink: true
    }
  },
  {
    path: '/sporthal-huren',
    name: 'book-indoor-hall',
    component: LandingPage,
    props: {
      title: 'Sporthal huren',
      showBookingLink: true
    }
  },
  {
    path: '/met-een-groepje-voetballen-in-de-buurt',
    name: 'activity-soccer',
    component: LandingPage,
    props: {
      title: 'Met een groepje voetballen in de buurt'
    }
  },
  {
    path: '/met-een-groepje-basketballen-pickup-games-in-de-buurt',
    name: 'activity-basketball',
    component: LandingPage,
    props: {
      title: 'Met een groepje basketballen, pickup games in de buurt'
    }
  },
  {
    path: '/met-een-groepje-zaalvoetballen-in-de-buurt',
    name: 'activity-futsal',
    component: LandingPage,
    props: {
      title: 'Met een groepje zaalvoetballen in de buurt'
    }
  },
  {
    path: '/met-een-groepje-badmintonnen-in-de-buurt',
    name: 'activity-badminton',
    component: LandingPage,
    props: {
      title: 'Met een groepje badmintonnen in de buurt'
    }
  },
  {
    path: '/met-een-groepje-beachvolleyballen-in-de-buurt',
    name: 'activity-beachvolleyball',
    component: LandingPage,
    props: {
      title: 'Met een groepje beachvolleyballen in de buurt'
    }
  },
  {
    path: '/met-een-groepje-wielrennen-in-de-buurt',
    name: 'activity-cycling',
    component: LandingPage,
    props: {
      title: 'Met een groepje wielrennen in de buurt'
    }
  },
  {
    path: '/met-een-groepje-hardlopen-in-de-buurt',
    name: 'activity-running',
    component: LandingPage,
    props: {
      title: 'Met een groepje hardlopen in de buurt'
    }
  },
  {
    path: '/baanzwemmen-in-de-buurt',
    name: 'activity-swim',
    component: LandingPage,
    props: {
      title: 'Baanzwemmen in de buurt'
    }
  }
]
