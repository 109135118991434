export const GROUP_CREATE = 'GROUP_CREATE'
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS'
export const GROUP_DELETE = 'GROUP_DELETE'
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS'
export const GROUP_ERROR = 'GROUP_ERROR'
export const GROUP_GET_ALL = 'GROUP_GET_ALL'
export const GROUP_GET_ALL_MORE = 'GROUP_GET_ALL_MORE'
export const GROUP_GET_ALL_SUCCESS = 'GROUP_GET_ALL_SUCCESS'
export const GROUP_GET_CREATED_OR_ADMIN = 'GROUP_GET_CREATED_OR_ADMIN'
export const GROUP_GET_CREATED_OR_ADMIN_SUCCESS =
  'GROUP_GET_CREATED_OR_ADMIN_SUCCESS'
export const GROUP_GET_DETAILS = 'GROUP_GET_DETAILS'
export const GROUP_GET_DETAILS_SUCCESS = 'GROUP_GET_DETAILS_SUCCESS'
export const GROUP_GET_EVENTS = 'GROUP_GET_EVENTS'
export const GROUP_GET_EVENTS_SUCCESS = 'GROUP_GET_EVENTS_SUCCESS'
export const GROUP_GET_JOINED = 'GROUP_GET_JOINED'
export const GROUP_GET_JOINED_SUCCESS = 'GROUP_GET_JOINED_SUCCESS'
export const GROUP_GET_LEAGUES = 'GROUP_GET_LEAGUES'
export const GROUP_GET_LEAGUES_SUCCESS = 'GROUP_GET_LEAGUES_SUCCESS'
export const GROUP_GET_UPCOMING_EVENTDATES = 'GROUP_GET_UPCOMING_EVENTDATES'
export const GROUP_GET_UPCOMING_EVENTDATES_SUCCESS =
  'GROUP_GET_UPCOMING_EVENTDATES_SUCCESS'
export const GROUP_GET_TEAMS = 'GROUP_GET_TEAMS'
export const GROUP_GET_TEAMS_SUCCESS = 'GROUP_GET_TEAMS_SUCCESS'
export const GROUP_JOIN = 'GROUP_JOIN'
export const GROUP_JOIN_SUCCESS = 'GROUP_JOIN_SUCCESS'
export const GROUP_LEAVE = 'GROUP_LEAVE'
export const GROUP_LEAVE_SUCCESS = 'GROUP_LEAVE_SUCCESS'
export const GROUP_REQUEST = 'GROUP_REQUEST'
export const GROUP_RESET_STATE = 'GROUP_RESET_STATE'
export const GROUP_SEARCH = 'GROUP_SEARCH'
export const GROUP_SEARCH_CLEAR = 'GROUP_SEARCH_CLEAR'
export const GROUP_SEARCH_SUCCESS = 'GROUP_SEARCH_SUCCESS'
export const GROUP_UPDATE = 'GROUP_UPDATE'
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS'
export const GROUP_UPDATE_MEMBER = 'GROUP_UPDATE_MEMBER'
export const GROUP_UPDATE_MEMBER_SUCCESS = 'GROUP_UPDATE_MEMBER_SUCCESS'
export const GROUP_UPLOAD_IMAGE = 'GROUP_UPLOAD_IMAGE'
export const GROUP_UPLOAD_IMAGE_ERROR = 'GROUP_UPLOAD_IMAGE_ERROR'
export const GROUP_UPLOAD_IMAGE_SUCCESS = 'GROUP_UPLOAD_IMAGE_SUCCESS'
