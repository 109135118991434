import {
  DATE_REQUEST,
  DATE_ERROR,
  DATE_UPCOMING_LIST,
  DATE_UPCOMING_LIST_SUCCESS,
  DATE_ITEM_BY_EVENT_ID,
  DATE_ITEM_BY_EVENT_ID_SUCCESS,
  DATE_LIST_BY_EVENT_ID,
  DATE_LIST_BY_EVENT_ID_SUCCESS,
  DATE_LIST_WITH_PARTICIPANTS_SUCCESS,
  DATE_ITEM_BY_DATE_ID,
  DATE_UPDATE_ATTENDANCE_SINGLE_ITEM,
  EVENT_DATES_GET,
  EVENT_DATES_GET_ERROR,
  EVENT_DATES_GET_START,
  EVENT_DATES_GET_SUCCESS,
  EVENT_DATES_GET_PARTICIPANTS,
  EVENT_DATES_GET_PARTICIPANTS_ERROR,
  EVENT_DATES_GET_PARTICIPANTS_START,
  EVENT_DATES_GET_PARTICIPANTS_SUCCESS,
  DATE_NEXT_EVENTS,
  DATE_NEXT_EVENTS_SUCCESS,
  DATE_UPCOMING,
  DATE_UPCOMING_SUCCESS,
  DATE_UPCOMING_RESET,
  DATE_UPDATE_DATE,
  DATE_UPDATE_DATE_SUCCESS,
  DATE_LOADING
} from '../actions/date'
import api from '@/utils/api'
import config from '@/utils/config'
import Vue from 'vue'
import { DateTime } from 'luxon'
import _ from 'lodash'
import noImage from '@/assets/images/img_no-image.png'
import { getImageUrl, formatDate } from '../modules/group'

const state = {
  status: '',
  dateItem: null,
  dateListByEvent: [],
  dateUpcomingList: {},
  eventDate: {},
  eventUpcomingIds: [],
  nextEvents: [],
  nextEventsCount: undefined,
  participantsPerEvent: [],
  participants: [],
  upcoming: {
    cities: [],
    count: undefined,
    events: [],
    skip: 0
  }
}

const getters = {
  participantList: (state) =>
    state.participants && state.participants.length
      ? state.participants.reduce((sortedList, participant) => {
          return {
            ...sortedList,
            [participant.attendance]: [
              ...(sortedList[participant.attendance] &&
              sortedList[participant.attendance].length
                ? sortedList[participant.attendance]
                : []),
              participant
            ]
          }
        }, {})
      : [],
  getEventUpcomingIds: (state) => state.eventUpcomingIds,
  getDateUpcomingList: (state) => state.dateUpcomingList,
  getParticipantsDateIds: (state) =>
    state.dateListByEvent.map((date) => date.participantDateId),
  currentAttendance: (state) =>
    state.dateListByEvent.reduce(
      (attendance, dateItem) => ({
        ...attendance,
        [dateItem.participantDateId]: dateItem.attendance
      }),
      {}
    )
}

const actions = {
  [DATE_UPCOMING_LIST]: ({ commit }) => {
    commit(DATE_REQUEST)
    return api
      .get('date', {
        params: {
          filter: {
            distinct: 'eventID',
            innerJoin: {
              targetTable: 'event',
              sourceColumn: 'eventID'
            },
            where: {
              endDateTime: {
                gte: new Date()
              }
            },
            order: 'startDateTime ASC',
            include: [
              {
                relation: 'participants',
                scope: {
                  fields: ['id', 'firstName', 'lastName']
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(DATE_UPCOMING_LIST_SUCCESS, resp)
        return true
      })
      .catch(() => {
        commit(DATE_ERROR)
        return true
      })
  },
  [DATE_ITEM_BY_EVENT_ID]: ({ commit }, eventID) => {
    commit(DATE_REQUEST)
    api
      .get('date', {
        params: {
          filter: {
            where: {
              eventID: eventID,
              endDateTime: {
                gte: new Date()
              }
            },
            order: 'startDateTime ASC',
            include: [
              {
                relation: 'participants',
                scope: {
                  fields: ['name', 'avatar', 'avatarUrl', 'id']
                }
              },
              {
                relation: 'event',
                scope: {
                  fields: ['timezoneOffset']
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        const dateItemsInFuture = resp.data.filter((dateItem) => {
          if (DateTime.local() < DateTime.fromISO(dateItem.endDateTime)) {
            return dateItem
          }
        })
        commit(DATE_ITEM_BY_EVENT_ID_SUCCESS, dateItemsInFuture[0])
        return resp.data
      })
      // Get participants for all the dates of this event
      .then((resp) => {
        api
          .post(
            resp.length > 0
              ? `date/${eventID}/participantsPerEvent`
              : `date/${eventID}/participantsPerFinishedEvent`
          )
          .then((resp) => {
            commit(DATE_LIST_WITH_PARTICIPANTS_SUCCESS, resp)
          })
          .catch(() => {
            commit(DATE_ERROR)
          })
      })
      .catch(() => {
        commit(DATE_ERROR)
      })
  },
  [DATE_LIST_BY_EVENT_ID]: ({ commit }, eventID) => {
    commit(DATE_REQUEST)
    api
      .post(`date/${eventID}/participantAttendance`)
      .then((resp) => {
        commit(DATE_LIST_BY_EVENT_ID_SUCCESS, resp)
      })
      .catch(() => {
        commit(DATE_ERROR)
      })
  },
  [DATE_ITEM_BY_DATE_ID]: ({ commit }, { eventId, dateId }) => {
    commit(DATE_REQUEST)
    api
      .get(`date/${dateId}`, {
        params: {
          filter: {
            include: [
              {
                relation: 'participants',
                scope: {
                  fields: ['name', 'avatar', 'avatarUrl', 'id']
                }
              },
              {
                relation: 'event',
                scope: {
                  fields: ['timezoneOffset']
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(DATE_ITEM_BY_EVENT_ID_SUCCESS, resp.data)
        return [resp.data]
      })
      .then(() => {
        api
          .post(`date/${eventId}/participantsPerEvent`, {
            dateId: parseFloat(dateId)
          })
          .then((resp) => {
            commit(DATE_LIST_WITH_PARTICIPANTS_SUCCESS, resp)
          })
          .catch(() => {
            commit(DATE_ERROR)
          })
      })
      .catch(() => {
        commit(DATE_ERROR)
      })
  },
  async [EVENT_DATES_GET]({ commit }, { dateId, securityHash }) {
    commit(EVENT_DATES_GET_START)
    try {
      const response = await api.get(`date/eventdate/${dateId}`, {
        params: {
          securityHash
        }
      })
      if (response) {
        commit(EVENT_DATES_GET_SUCCESS, response.data)
      } else {
        commit(EVENT_DATES_GET_ERROR, 'something went wrong')
      }
    } catch (e) {
      commit(EVENT_DATES_GET_ERROR, e)
    }
  },
  async [EVENT_DATES_GET_PARTICIPANTS]({ commit }, { dateId }) {
    commit(EVENT_DATES_GET_PARTICIPANTS_START)
    try {
      const response = await api.get(`date/participants/${dateId}`)
      if (response) {
        commit(EVENT_DATES_GET_PARTICIPANTS_SUCCESS, response.data)
      } else {
        commit(EVENT_DATES_GET_PARTICIPANTS_ERROR, 'something went wrong')
      }
    } catch (e) {
      commit(EVENT_DATES_GET_PARTICIPANTS_ERROR, e)
    }
  },
  async [DATE_NEXT_EVENTS]({ commit }, { skip, limit }) {
    commit(DATE_REQUEST, DATE_NEXT_EVENTS)
    try {
      const response = await api.get(
        `date/nextEvents?skip=${skip}&limit=${limit}`
      )
      if (response) {
        commit(DATE_NEXT_EVENTS_SUCCESS, { data: response.data, skip })
        if (response.data && response.data.length) {
          return response.data
        } else {
          return []
        }
      } else {
        commit(DATE_ERROR, DATE_NEXT_EVENTS)
        return []
      }
    } catch (e) {
      commit(DATE_ERROR, DATE_NEXT_EVENTS)
      return []
    }
  },
  async [DATE_UPCOMING](
    { commit },
    { skip, limit, city, activity, cancelToken }
  ) {
    commit(DATE_REQUEST, DATE_UPCOMING)
    try {
      const response = await api.get(
        `date/upcoming?skip=${skip}&limit=${limit}&city=${city}&activity=${activity}`,
        cancelToken
      )
      if (response) {
        commit(DATE_UPCOMING_SUCCESS, { data: response.data, limit })
        if (
          response.data &&
          response.data.events &&
          response.data.events.length
        ) {
          return response.data.events
        } else {
          return []
        }
      } else {
        commit(DATE_ERROR, DATE_UPCOMING)
        return []
      }
    } catch (e) {
      commit(DATE_ERROR, DATE_UPCOMING)
      return []
    }
  },
  async [DATE_UPDATE_DATE]({ commit }, { dateId, dateItem }) {
    commit(DATE_REQUEST, dateItem)
    try {
      const response = await api.patch(`date/${dateId}`, dateItem)
      if (response && response.status === 200) {
        commit(DATE_UPDATE_DATE_SUCCESS, response.data)
        return response.data
      } else {
        commit(DATE_ERROR, DATE_UPDATE_DATE)
      }
    } catch (e) {
      commit(DATE_ERROR, DATE_UPDATE_DATE)
    }
  }
}

const mutations = {
  [DATE_LOADING]: (state, loading) => {
    state.loading = loading
  },
  [DATE_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [DATE_UPCOMING_LIST_SUCCESS]: (state, resp) => {
    // Group by event id
    // wordt nu niet gebruikt, dus weet niet of dit werkt, maar moet hier uberhaupt geen lodash gebruiken, kan makkelijk met normale js
    const dateUpcomingList = _.compose(
      _.groupBy((x) => x.eventID),
      _.map((value, key) => ({ eventID: key, dates: value })),
      _.value()
    )(resp.data)

    // Get only event id
    const eventUpcomingIds = _.uniq(_.map(dateUpcomingList, 'eventID'))

    state.status = 'success'
    Vue.set(state, 'dateUpcomingList', dateUpcomingList)
    Vue.set(state, 'eventUpcomingIds', eventUpcomingIds)
  },
  [DATE_ITEM_BY_EVENT_ID_SUCCESS]: (state, resp) => {
    if (resp !== undefined) {
      resp.dateFormatted = itemFormatter.date(resp.startDateTime)
      resp.startTimeFormatted = itemFormatter.time(resp.startDateTime)
      resp.endTimeFormatted = itemFormatter.time(resp.endDateTime)
      for (const property in resp.participants) {
        const participant = resp.participants[property]
        resp.participants[property].avatarUrl =
          participant.avatarUrl !== null
            ? config.baseUrl + participant.avatarUrl
            : null
      }
      Vue.set(state, 'dateItem', resp)
    } else {
      Vue.set(state, 'dateItem', null)
    }
  },
  [DATE_LIST_BY_EVENT_ID_SUCCESS]: (state, resp) => {
    const formattedDateListResponse = resp.data.participantAttendance.map(
      (attendance) => {
        return {
          attendance: attendance.attendance,
          id: attendance.id,
          isFull: attendance.isFull,
          participantDateId: attendance.participantDateId,
          timezoneOffset: attendance.timezoneOffset,
          fullDate: attendance.startDateTime,
          dateFormatted: itemFormatter.date(attendance.startDateTime),
          numberOfParticipants: attendance.numberOfParticipants,
          startTimeFormatted: itemFormatter.time(attendance.startDateTime),
          endTimeFormatted: itemFormatter.time(attendance.endDateTime),
          paymentStatus: attendance.paymentStatus
        }
      }
    )
    Vue.set(state, 'dateListByEvent', formattedDateListResponse)
  },
  [DATE_UPDATE_ATTENDANCE_SINGLE_ITEM]: (state, data) => {
    state.dateListByEvent = state.dateListByEvent.map((item) => {
      return item.id === data.dateID
        ? {
            ...item,
            attendance: data.attendance
          }
        : item
    })
  },
  [DATE_LIST_WITH_PARTICIPANTS_SUCCESS]: (state, resp) => {
    let participants
    if (resp.data.finishedEvent) {
      if (resp.data.participantsWithAttendance.length > 0) {
        const mergedParticipants = resp.data.participantsWithAttendance.reduce(
          (acc, curr) => {
            return [...acc, ...curr.participants]
          },
          []
        )
        const uniqueParticipants = mergedParticipants.filter(
          (participant, idx, self) => {
            return (
              idx ===
              self.findIndex(
                (t) => t.participantId === participant.participantId
              )
            )
          }
        )
        participants = uniqueParticipants.map((participant) => {
          if (participant.avatarUrl) {
            participant.avatarUrl = config.baseUrl + participant.avatarUrl
          }
          return participant
        })
      } else {
        participants = []
      }
    } else {
      if (
        resp.data &&
        resp.data.participantsWithAttendance &&
        resp.data.participantsWithAttendance[0] &&
        resp.data.participantsWithAttendance[0].participants
      ) {
        participants = resp.data.participantsWithAttendance[0].participants.map(
          (participant) => {
            if (participant.avatarUrl) {
              participant.avatarUrl = config.baseUrl + participant.avatarUrl
            }
            return participant
          }
        )
      } else {
        participants = []
      }
    }
    Vue.set(state, 'dateListWithParticipants', participants)
  },
  [DATE_ERROR]: (state) => {
    state.status = 'error'
  },
  [EVENT_DATES_GET_ERROR]: (state) => {
    state.status = 'error'
  },
  [EVENT_DATES_GET_START]: (state) => {
    state.status = 'loading'
  },
  [EVENT_DATES_GET_SUCCESS]: (state, eventDate) => {
    state.status = 'success'
    state.eventDate = {
      ...eventDate,
      date: DateTime.fromISO(eventDate.startDateTime).toLocaleString(
        DateTime.DATE_MED
      ),
      dateWithDay: DateTime.fromISO(eventDate.startDateTime).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
      endDateTimeFormatted: DateTime.fromISO(eventDate.endDateTime).toFormat(
        'HH:mm'
      ),
      imageUrl: eventDate.imageUrl
        ? `${config.baseUrl}${eventDate.imageUrl}`
        : noImage,
      url: `${config.appUrl}/events/${eventDate.id}`, // hash?
      startDateTimeFormatted: DateTime.fromISO(
        eventDate.startDateTime
      ).toFormat('HH:mm')
    }
    state.participants = eventDate.participants
      ? eventDate.participants.map((participant) => ({
          ...participant,
          ...(participant.avatarUrl
            ? { avatarUrl: `${config.baseUrl}${participant.avatarUrl}` }
            : { avatarUrl: null })
        }))
      : []
  },
  [EVENT_DATES_GET_PARTICIPANTS_ERROR]: (state) => {
    state.status = 'error'
  },
  [EVENT_DATES_GET_PARTICIPANTS_START]: (state) => {
    state.status = 'loading'
  },
  [EVENT_DATES_GET_PARTICIPANTS_SUCCESS]: (state, participants) => {
    state.status = 'success'
    state.participants = participants.map((participant) => ({
      ...participant,
      ...(participant.avatarUrl
        ? { avatarUrl: `${config.baseUrl}${participant.avatarUrl}` }
        : { avatarUrl: noImage })
    }))
  },
  [DATE_NEXT_EVENTS_SUCCESS]: (state, { data }) => {
    state.status = 'success'
    let newDates = []
    if (data && data.length) {
      newDates = data.map((eventdate) => ({
        ...eventdate,
        image:
          eventdate.image && Object.keys(eventdate.image).length
            ? getImageUrl(eventdate.image)
            : null,
        date: formatDate.short(eventdate.startDateTime),
        endTime: formatDate.time(eventdate.endDateTime),
        startTime: formatDate.time(eventdate.startDateTime),
        securityHash: eventdate.securityHash
      }))
    }
    state.nextEvents = [...state.nextEvents, ...newDates]
  },
  [DATE_UPCOMING_SUCCESS]: (state, { data, limit }) => {
    state.status = 'success'
    let newDates = []
    if (data && data.events && data.events.length) {
      newDates = data.events.map((eventdate) => ({
        ...eventdate,
        image:
          eventdate.image && Object.keys(eventdate.image).length
            ? getImageUrl(eventdate.image)
            : null,
        date: formatDate.short(eventdate.startDateTime),
        endTime: formatDate.time(eventdate.endDateTime),
        startTime: formatDate.time(eventdate.startDateTime),
        securityHash: eventdate.securityHash
      }))
    }
    state.upcoming = {
      cities: data.cities,
      count: data.count,
      events: [...state.upcoming.events, ...newDates],
      skip: state.upcoming.skip + limit
    }
  },
  [DATE_UPDATE_DATE_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [DATE_UPCOMING_RESET]: (state) => {
    state.upcoming = {
      cities: [],
      count: undefined,
      events: [],
      skip: 0
    }
  }
}

export const itemFormatter = {
  time: (timestamp) => DateTime.fromISO(timestamp).toFormat('HH:mm'),
  date: (timestamp) => DateTime.fromISO(timestamp).toFormat('ccc d MMM')
}

export default {
  state,
  getters,
  actions,
  mutations
}
