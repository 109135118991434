import {
  TEAM_ADD_MEMBERS,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE,
  TEAM_ERROR,
  TEAM_GET_DETAILS_SUCCESS,
  TEAM_GET_DETAILS,
  TEAM_GET_MEMBERS,
  TEAM_GET_MEMBERS_SUCCESS,
  TEAM_GET_NAMES,
  TEAM_GET_NAMES_SUCCESS,
  TEAM_REQUEST,
  TEAM_RESET_STATE,
  TEAM_UPDATE,
  TEAM_UPDATE_SUCCESS
} from '../actions/team'
import { GROUP_UPLOAD_IMAGE } from '../actions/group'
import { getImageUrl } from '../modules/group'
import api from '@/utils/api'
import store from '../index'

const state = {
  loading: false,
  error: null,
  teamDetails: {},
  createdTeam: null,
  updatedTeam: null,
  teamMembers: [],
  teamListById: null
}

const getters = {
  teamListById: (state) => state.teamListById
}

const actions = {
  [TEAM_GET_DETAILS]: async ({ commit }, teamId) => {
    commit(TEAM_REQUEST)
    try {
      const resp = await api.get(`team/${teamId}`, {
        params: {
          filter: {
            include: {
              relation: 'lineups',
              scope: {
                limit: 1,
                where: {
                  end_date: null
                }
              }
            }
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(TEAM_GET_DETAILS_SUCCESS, resp.data)
      } else {
        commit(TEAM_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(TEAM_ERROR, `Something went wrong: ${err}`)
    }
  },
  [TEAM_CREATE]: async (
    { commit, dispatch },
    { groupId, team: { name, image, members } }
  ) => {
    commit(TEAM_REQUEST)
    try {
      const imageUploadSuccess =
        image && (await dispatch(GROUP_UPLOAD_IMAGE, image))
      const resp = await api.post('team', {
        name: name,
        groupID: groupId,
        image: imageUploadSuccess ? store.getters.newImage : null
      })
      if (resp.status === 200 && resp.data) {
        const createdTeam = resp.data
        if (members && members.length) {
          await dispatch(TEAM_ADD_MEMBERS, {
            groupID: groupId,
            teamID: createdTeam.id,
            members
          })
        }
        commit(TEAM_CREATE_SUCCESS, createdTeam)
      } else {
        commit(TEAM_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(TEAM_ERROR, `Something went wrong: ${err}`)
    }
  },
  [TEAM_UPDATE]: async (
    { commit, dispatch },
    { teamId, team: { name, image, members } }
  ) => {
    commit(TEAM_REQUEST)
    try {
      const imageUploadSuccess =
        !!image && (await dispatch(GROUP_UPLOAD_IMAGE, image))
      const resp = await api.patch(`team/${teamId}`, {
        name: name,
        ...(image && {
          image: imageUploadSuccess ? store.getters.newImage : null
        })
      })
      if (resp.status === 200 && resp.data) {
        const updatedTeam = resp.data
        if (members && members.length) {
          await dispatch(TEAM_ADD_MEMBERS, {
            groupID: updatedTeam.groupID,
            teamID: updatedTeam.id,
            members
          })
        }
        commit(TEAM_UPDATE_SUCCESS, resp.data)
      } else {
        commit(TEAM_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(TEAM_ERROR, `Something went wrong: ${err}`)
    }
  },
  [TEAM_ADD_MEMBERS]: async ({ commit }, { groupID, teamID, members }) => {
    try {
      const resp = await api.post('lineup', {
        groupID,
        teamID,
        members: members.map((member) => member.id)
      })
      if (resp.status !== 200 || !resp.data) {
        commit(TEAM_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(TEAM_ERROR, `Something went wrong: ${err}`)
    }
  },
  [TEAM_GET_MEMBERS]: async ({ commit }, { lineupId }) => {
    try {
      const resp = await api.get('lineup/members', {
        params: {
          lineupId
        }
      })
      if (resp.status === 200 || !resp.data) {
        commit(TEAM_GET_MEMBERS_SUCCESS, resp.data)
      } else {
        commit(TEAM_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(TEAM_ERROR, `Something went wrong: ${err}`)
    }
  },
  [TEAM_GET_NAMES]: async ({ commit }, teamIds) => {
    try {
      const resp = await api.get('team', {
        params: {
          filter: {
            where: { id: { inq: teamIds } }
          }
        }
      })
      if (resp.status === 200 || !resp.data) {
        commit(TEAM_GET_NAMES_SUCCESS, resp.data)
      } else {
        commit(TEAM_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(TEAM_ERROR, `Something went wrong: ${err}`)
    }
  }
}

const mutations = {
  [TEAM_REQUEST]: (state) => {
    state.loading = true
  },
  [TEAM_ERROR]: (state, errorMessage) => {
    state.loading = false
    state.error = errorMessage
  },
  [TEAM_GET_DETAILS_SUCCESS]: (state, resp) => {
    state.loading = false
    state.teamDetails = {
      activeLineupId: resp.lineups[0].id,
      groupId: resp.groupID,
      id: resp.id,
      imageUrl: getImageUrl(resp.image),
      name: resp.name,
      ownerId: resp.ownerId
    }
  },
  [TEAM_CREATE_SUCCESS]: (state, resp) => {
    state.loading = false
    state.createdTeam = resp
  },
  [TEAM_UPDATE_SUCCESS]: (state, resp) => {
    state.loading = false
    state.updatedTeam = resp
  },
  [TEAM_RESET_STATE]: (state) => {
    state.loading = false
    state.error = null
    state.createdTeam = null
    state.updatedTeam = null
    state.groupDetails = {}
  },
  [TEAM_GET_MEMBERS_SUCCESS]: (state, resp) => {
    state.loading = false
    state.teamMembers = resp.map((member) => ({
      ...member,
      avatarUrl: getImageUrl(member.avatar)
    }))
  },
  [TEAM_GET_NAMES_SUCCESS]: (state, resp) => {
    state.loading = false
    state.teamListById = resp.reduce((teamlist, team) => {
      return { ...teamlist, [team.id]: team.name }
    }, {})
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
