import {
  LOCATION_REQUEST,
  LOCATION_REQUEST_SUCCESS,
  LOCATION_ERROR,
  LOCATION_LIST,
  LOCATION_LIST_SUCCESS,
  LOCATION_VIEW,
  LOCATION_VIEW_SUCCESS,
  LOCATION_CITY_LIST,
  LOCATION_CITY_LIST_SUCCESS,
  LOCATION_CREATE,
  EVENT_UPDATE_LOCATION,
  LOCATION_ADDRESS_COORDINATES,
  LOCATION_ADDRESS_COORDINATES_SUCCESS,
  LOCATION_TYPED_LOCATIONS,
  LOCATION_TYPED_LOCATIONS_SUCCESS,
  LOCATION_TYPES_LOCATIONS_RESET,
  LOCATION_TYPES_LOCATIONS_RESET_SUCCESS,
  LOCATION_MAKE_RESERVATION,
  LOCATION_MAKE_RESERVATION_SUCCESS,
  LOCATION_GET_BY_ID,
  LOCATION_GET_BY_ID_SUCCESS,
  LOCATION_REMOVE_BY_ID,
  LOCATION_REMOVE_BY_ID_SUCCESS,
  LOCATION_GET_COMMERCIAL,
  LOCATION_GET_COMMERCIAL_SUCCESS,
  LOCATION_GET_COMMERCIAL_BY_SLUG,
  LOCATION_GET_COMMERCIAL_BY_SLUG_SUCCESS,
  LOCATION_GET_COMMERCIAL_AVAILABILITY,
  LOCATION_GET_COMMERCIAL_AVAILABILITY_SUCCESS,
  LOCATION_BOOK,
  LOCATION_BOOK_SUCCESS,
  LOCATION_BY_USER_ID,
  LOCATION_BY_USER_ID_SUCCESS,
  LOCATION_BOOK_COMMERCIAL
} from '../actions/location'
import { EVENT_ITEM_SET_LOCATION, EVENT_CREATE_SUCCESS } from '../actions/event'
import update from 'immutability-helper'
import api, { googleSearchLongAndLatOnMap } from '@/utils/api'
import Vue from 'vue'
import noImage from '@/assets/images/img_no-image.png'
import config from '@/utils/config'
import store from '@/store'
import { PRICING_PARAM_MEMBER } from '@/utils/constants'
import { version } from '../../../package.json'

const state = {
  status: '',
  locationView: null,
  locationList: [],
  locationCityList: [],
  addressCoordinates: null,
  details: null,
  commercial: [],
  previousLocations: [],
  commercialAvailability: []
}

const getters = {}

const actions = {
  [LOCATION_MAKE_RESERVATION]: ({ commit }, data) => {
    commit(LOCATION_REQUEST)
    return api
      .post(`location/${data.id}/sendEmailToLocation`, {
        startDate: data.startDate,
        startTime: data.startTime,
        endTime: data.endTime,
        isRecurring: data.isRecurring,
        currentUserId: data.currentUserId
      })
      .then((res) => {
        commit(LOCATION_MAKE_RESERVATION_SUCCESS, res)
        return res
      })
      .catch((err) => {
        commit(LOCATION_ERROR)
        return err
      })
  },
  [LOCATION_TYPES_LOCATIONS_RESET]: ({ commit }) => {
    commit(LOCATION_TYPES_LOCATIONS_RESET_SUCCESS)
  },
  [LOCATION_ADDRESS_COORDINATES]: ({ commit }, searchAdress) => {
    commit(LOCATION_REQUEST)
    return googleSearchLongAndLatOnMap(searchAdress)
      .then((res) => {
        commit(LOCATION_ADDRESS_COORDINATES_SUCCESS, res)
        return res
      })
      .catch((err) => {
        commit(LOCATION_ERROR)
        return err
      })
  },
  [LOCATION_LIST]: ({ commit }, requestParams) => {
    commit(LOCATION_REQUEST)
    return api
      .post('location/recreateX', {
        startTime: requestParams.startTime,
        endTime: requestParams.endTime,
        recurring: requestParams.recurring,
        startDate: requestParams.startDate,
        endDate: requestParams.endDate
      })
      .then((resp) => {
        commit(LOCATION_LIST_SUCCESS, resp)
        return true
      })
      .catch(() => {
        commit(LOCATION_ERROR)
      })
  },
  [LOCATION_TYPED_LOCATIONS]: ({ commit }, locationTypeId) => {
    commit(LOCATION_REQUEST)
    return api
      .get('location', {
        params: {
          filter: {
            where: {
              location_typeID: locationTypeId
            }
          }
        }
      })
      .then((res) => {
        commit(LOCATION_TYPED_LOCATIONS_SUCCESS, res.data)
      })
      .catch((err) => {
        commit(LOCATION_ERROR)
        return err
      })
  },
  [EVENT_UPDATE_LOCATION]: ({ commit }, { eventID, locationID }) => {
    commit(LOCATION_REQUEST)
    return api
      .patch(`/event/${eventID}`, {
        locationID
      })
      .then(() => true)
      .catch(() => false)
  },
  [LOCATION_VIEW]: ({ commit }, locationID) => {
    commit(LOCATION_REQUEST)
    api
      .get('location/' + locationID)
      .then((resp) => {
        commit(LOCATION_VIEW_SUCCESS, resp)
      })
      .catch(() => {
        commit(LOCATION_ERROR)
      })
  },
  [LOCATION_CREATE]: ({ commit }, item) => {
    commit(LOCATION_REQUEST)
    return new Promise((resolve, reject) => {
      api
        .post('location', {
          name: item.name,
          adress: item.address + ' ' + item.streetNumber,
          postalCode: item.postalCode,
          city: item.locality,
          country: item.country,
          entranceInstructions: null,
          recreateXPlaceId: null,
          coordinates: {
            lat: item.lat,
            lng: item.lng
          },
          // Location is created with user created as default value
          location_typeID: 1,
          cost: 0,
          locationContact: null
        })
        .then((resp) => {
          commit(EVENT_ITEM_SET_LOCATION, resp.data.id)
          resolve(resp)
        })
        .catch((resp) => {
          commit(LOCATION_ERROR)
          reject(resp)
        })
    })
  },
  [LOCATION_CITY_LIST]: ({ commit, dispatch }) => {
    commit(LOCATION_REQUEST)
    dispatch(LOCATION_LIST)
      .then((resp) => {
        commit(LOCATION_CITY_LIST_SUCCESS, resp)
      })
      .catch(() => {
        commit(LOCATION_ERROR)
      })
  },
  [LOCATION_GET_BY_ID]: async ({ commit }, id) => {
    try {
      const resp = await api.get('location', {
        params: {
          filter: {
            where: {
              id
            },
            limit: 1
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(LOCATION_GET_BY_ID_SUCCESS, resp.data[0])
      } else {
        commit(LOCATION_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(LOCATION_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LOCATION_REMOVE_BY_ID]: async ({ commit }, id) => {
    commit(LOCATION_REQUEST, { [LOCATION_REMOVE_BY_ID]: id })
    try {
      const resp = await api.delete(`location/${id}`)
      if (resp.status === 200 && resp.data && resp.data.count > 0) {
        commit(LOCATION_REMOVE_BY_ID_SUCCESS, resp.data)
        return true
      } else {
        commit(
          LOCATION_ERROR,
          `Something went wrong while deleting a location: ${resp.statusText}`
        )
        return false
      }
    } catch (err) {
      commit(
        LOCATION_ERROR,
        `Something went wrong while deleting a location: ${err}`
      )
      return false
    }
  },
  [LOCATION_GET_COMMERCIAL]: async ({ commit }) => {
    commit(LOCATION_REQUEST)
    try {
      const resp = await api.get('commercial_location?expand=external')
      if (resp.status === 200 && resp.data) {
        commit(LOCATION_GET_COMMERCIAL_SUCCESS, resp.data)
      } else {
        commit(LOCATION_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(LOCATION_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LOCATION_GET_COMMERCIAL_BY_SLUG]: async ({ commit }, slug) => {
    commit(LOCATION_REQUEST)
    try {
      const resp = await api.get('commercial_location', {
        params: {
          filter: {
            where: {
              slug
            }
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(LOCATION_GET_COMMERCIAL_BY_SLUG_SUCCESS)
        return resp.data[0]
      } else {
        commit(LOCATION_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(LOCATION_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LOCATION_GET_COMMERCIAL_AVAILABILITY]: async (
    { commit },
    { locationId, date }
  ) => {
    commit(LOCATION_REQUEST)
    try {
      const resp = await api.get(
        `commercial_location/${locationId}/availability?date=${date}`
      )
      if (resp.status === 200 && resp.data) {
        commit(LOCATION_GET_COMMERCIAL_AVAILABILITY_SUCCESS, resp.data)
      } else {
        commit(LOCATION_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(LOCATION_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LOCATION_BOOK]: async ({ commit }, eventItem) => {
    commit(LOCATION_REQUEST)
    try {
      const resp = await api.post('location/booking', { event: eventItem })
      if (resp.status === 200 && resp.data) {
        commit(LOCATION_BOOK_SUCCESS, resp.data)
        commit(EVENT_CREATE_SUCCESS)
      } else {
        commit(LOCATION_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(LOCATION_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LOCATION_BY_USER_ID]: async ({ commit }) => {
    commit(LOCATION_REQUEST, LOCATION_BY_USER_ID)
    try {
      const resp = await api.get(
        `participant/${store.getters.authId}/locationOwnerOf`,
        { params: { filter: { order: 'name ASC' } } }
      )
      if (resp.status === 200 && resp.data) {
        commit(LOCATION_BY_USER_ID_SUCCESS, resp.data)
      } else {
        commit(LOCATION_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(LOCATION_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LOCATION_BOOK_COMMERCIAL]: async ({ commit }, booking) => {
    commit(LOCATION_REQUEST, LOCATION_BOOK_COMMERCIAL)
    try {
      const resp = await api.post(
        `booking`,
        {
          ...booking,
          data: {
            source: 'website'
          }
        },
        {
          headers: {
            'x-site-version': version
          }
        }
      )
      if (resp.status === 200 && resp.data) {
        commit(LOCATION_REQUEST_SUCCESS)
        return resp.data
      } else {
        commit(LOCATION_ERROR, `Something went wrong: ${resp.statusText}`)
        return null
      }
    } catch (err) {
      commit(LOCATION_ERROR, `Something went wrong: ${err}`)
      return null
    }
  }
}

const mutations = {
  [LOCATION_MAKE_RESERVATION_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [LOCATION_TYPES_LOCATIONS_RESET_SUCCESS]: (state) => {
    Vue.set(state, 'locationList', [])
  },
  [LOCATION_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [LOCATION_REQUEST_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [LOCATION_ERROR]: (state) => {
    state.status = 'error'
  },
  [EVENT_ITEM_SET_LOCATION]: (state) => {
    state.status = 'success'
  },
  [LOCATION_LIST_SUCCESS]: (state, resp) => {
    state.status = 'success'
    const locationList = []
    for (const property in resp.data) {
      if (!resp.data.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
      const locationItem = resp.data[property]
      locationList.push(locationItemFormatter.formatItem(locationItem))
    }
    const updatedList = update(state.locationList, {
      $push: locationList
    })
    Vue.set(state, 'locationList', updatedList)
  },
  [LOCATION_ADDRESS_COORDINATES_SUCCESS]: (state, res) => {
    state.status = 'success'
    Vue.set(state, 'addressCoordinates', res.location)
  },
  [LOCATION_VIEW_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'locationView', locationItemFormatter.formatItem(resp.data))
  },
  [LOCATION_CITY_LIST_SUCCESS]: (state) => {
    state.status = 'success'
    // todo: remove LOCATION_LIST, LOCATION_CITY_LIST_SUCCESS if api call available
    // Vue.set(state, 'locationCityList', _.sortBy(_.uniq(_.map(state.locationList, 'city'))), function (value) { return value })
  },
  [LOCATION_TYPED_LOCATIONS_SUCCESS]: (state, res) => {
    const locationList = []
    res.forEach((item) => {
      const updatedItem = locationItemFormatter.formatItem(item)
      locationList.push(updatedItem)
    })
    const updatedList = update(state.locationList, {
      $push: locationList
    })
    Vue.set(state, 'locationList', updatedList)
  },
  [LOCATION_GET_BY_ID_SUCCESS]: (state, resp) => {
    state.details = resp
  },
  [LOCATION_REMOVE_BY_ID_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [LOCATION_GET_COMMERCIAL_SUCCESS]: (state, data) => {
    state.status = 'success'
    state.commercial = data.map(
      ({
        activities,
        address,
        coordinates,
        creditcards,
        description,
        extra_info,
        id,
        imageUrl,
        name,
        pricing,
        slug,
        timeslots
      }) => ({
        id,
        name,
        address,
        extra_info,
        coordinates,
        creditcards,
        description,
        imageUrl: imageUrl ? `${config.baseUrl}${imageUrl}` : noImage,
        slug,
        activities,
        timeslots,
        pricing,
        hasMemberPrice:
          (pricing?.params &&
            !!pricing?.params.find(
              (param) => param.key === PRICING_PARAM_MEMBER
            )) ||
          false,
        lowestMemberPrice: pricing?.data?.reduce(
          (res, item) =>
            item.member && (!res.amount || item.price < res.amount)
              ? { amount: item.price, duration: item.duration }
              : res,
          {}
        ),
        lowestNonMemberPrice: pricing?.data?.reduce(
          (res, item) =>
            !item.member && (!res.amount || item.price < res.amount)
              ? { amount: item.price, duration: item.duration }
              : res,
          {}
        )
      })
    )
  },
  [LOCATION_GET_COMMERCIAL_AVAILABILITY_SUCCESS]: (state, data) => {
    state.status = 'success'
    state.commercialAvailability = data
  },
  [LOCATION_GET_COMMERCIAL_BY_SLUG_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [LOCATION_BOOK_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [LOCATION_BY_USER_ID_SUCCESS]: (state, data) => {
    state.status = 'success'
    state.previousLocations = data
  }
}

const locationItemFormatter = {
  formatItem: function (locationItem) {
    locationItem.cost =
      isNaN(locationItem.cost) || locationItem.cost === null
        ? '0'
        : locationItem.cost
    locationItem.image = locationItemFormatter.image(locationItem.image)
    locationItem.address = locationItem.adress
    return locationItem
  },
  image: function (image) {
    if (image === undefined || image === null) {
      image = noImage
    }
    return image
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
