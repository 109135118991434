import {
  LEAGUE_CREATE_SUCCESS,
  LEAGUE_CREATE,
  LEAGUE_ERROR,
  LEAGUE_GET_DETAILS,
  LEAGUE_GET_DETAILS_SUCCESS,
  LEAGUE_GET_MATCHES_SUCCESS,
  LEAGUE_GET_RANKING,
  LEAGUE_GET_RANKING_SUCCESS,
  LEAGUE_GET_RESULTS,
  LEAGUE_GET_RESULTS_SUCCESS,
  LEAGUE_GET_MATCHES,
  LEAGUE_REQUEST,
  LEAGUE_RESET_STATE,
  LEAGUE_UPDATE,
  LEAGUE_UPDATE_SUCCESS
} from '../actions/league'
import { TEAM_GET_NAMES } from '../actions/team'
import { GROUP_UPLOAD_IMAGE } from '../actions/group'
import { getImageUrl, formatDate } from '../modules/group'
import api from '@/utils/api'
import store from '../index'
import moment from 'moment'

const state = {
  loading: false,
  error: null,
  leagueDetails: {},
  ranking: null,
  createdLeague: null,
  updatedLeague: null,
  matches: null
}

const getters = {}

const actions = {
  [LEAGUE_GET_DETAILS]: async ({ commit }, leagueId) => {
    commit(LEAGUE_REQUEST)
    try {
      const resp = await api.get(`league/${leagueId}`)
      if (resp.status === 200 && resp.data) {
        commit(LEAGUE_GET_DETAILS_SUCCESS, resp.data)
      } else {
        commit(LEAGUE_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (err) {
      commit(LEAGUE_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LEAGUE_CREATE]: async (
    { commit, dispatch },
    { groupId, league: { name, image } }
  ) => {
    commit(LEAGUE_REQUEST)
    try {
      const imageUploadSuccess =
        image && (await dispatch(GROUP_UPLOAD_IMAGE, image))
      const resp = await api.post('league', {
        name: name,
        groupID: groupId,
        type: 1,
        image: imageUploadSuccess ? store.getters.newImage : null
      })
      if (resp.status === 200 && resp.data) {
        commit(LEAGUE_CREATE_SUCCESS, resp.data)
      } else {
        commit(LEAGUE_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (err) {
      commit(LEAGUE_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LEAGUE_UPDATE]: async (
    { commit, dispatch },
    { leagueId, league: { name, image } }
  ) => {
    commit(LEAGUE_REQUEST)
    try {
      const imageUploadSuccess =
        image && (await dispatch(GROUP_UPLOAD_IMAGE, image))
      const resp = await api.patch(`league/${leagueId}`, {
        name: name,
        ...(image && {
          image: imageUploadSuccess ? store.getters.newImage : null
        })
      })
      if (resp.status === 200 && resp.data) {
        commit(LEAGUE_UPDATE_SUCCESS, resp.data)
      } else {
        commit(LEAGUE_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (err) {
      commit(LEAGUE_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LEAGUE_GET_MATCHES]: async ({ commit, dispatch }, { leagueId }) => {
    commit(LEAGUE_REQUEST)
    try {
      const resp = await api.get(`league/${leagueId}/matches`, {
        params: {
          filter: {
            include: {
              relation: 'date',
              scope: {
                include: {
                  relation: 'event'
                }
              }
            }
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        await dispatch(TEAM_GET_NAMES, getLineupIdsFromMatches(resp.data))
        commit(LEAGUE_GET_MATCHES_SUCCESS, resp.data)
      } else {
        commit(LEAGUE_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (err) {
      commit(LEAGUE_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LEAGUE_GET_RANKING]: async ({ commit, dispatch }, leagueId) => {
    commit(LEAGUE_REQUEST)
    try {
      const resp = await api.get('ranking', {
        params: {
          filter: {
            where: {
              leagueID: leagueId
            }
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        const ranking = resp.data[0]
        if (ranking) {
          await dispatch(TEAM_GET_NAMES, Object.keys(ranking.team_ranking))
          const teams = store.getters.teamListById
          commit(LEAGUE_GET_RANKING_SUCCESS, { ranking, teams })
        } else {
          commit(LEAGUE_GET_RANKING_SUCCESS, { ranking })
        }
      } else {
        commit(LEAGUE_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (err) {
      commit(LEAGUE_ERROR, `Something went wrong: ${err}`)
    }
  },
  [LEAGUE_GET_RESULTS]: async ({ commit, dispatch }, leagueId) => {
    commit(LEAGUE_REQUEST)
    try {
      const resp = await api.get('result', {
        params: {
          filter: {
            where: {
              leagueID: leagueId
            }
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        const teamIdsInResults = getTeamIdsFromResults(resp.data)
        await dispatch(TEAM_GET_NAMES, teamIdsInResults)
        const teams = store.getters.teamListById
        commit(LEAGUE_GET_RESULTS_SUCCESS, { results: resp.data, teams })
      } else {
        commit(LEAGUE_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (err) {
      commit(LEAGUE_ERROR, `Something went wrong: ${err}`)
    }
  }
}

const mutations = {
  [LEAGUE_REQUEST]: (state) => {
    state.loading = true
  },
  [LEAGUE_ERROR]: (state, errorMessage) => {
    state.loading = false
    state.error = errorMessage
  },
  [LEAGUE_GET_DETAILS_SUCCESS]: (state, resp) => {
    state.loading = false
    state.leagueDetails = {
      ...resp,
      imageUrl: getImageUrl(resp.image)
    }
  },
  [LEAGUE_CREATE_SUCCESS]: (state, resp) => {
    state.loading = false
    state.createdLeague = resp
  },
  [LEAGUE_UPDATE_SUCCESS]: (state, resp) => {
    state.loading = false
    state.updatedLeague = resp
  },
  [LEAGUE_GET_MATCHES_SUCCESS]: (state, resp) => {
    state.loading = false
    state.matches = formatMatches(resp)
  },
  [LEAGUE_GET_RANKING_SUCCESS]: (state, { ranking, teams }) => {
    state.loading = false
    state.ranking = ranking && teams ? formatRanking(ranking, teams) : []
  },
  [LEAGUE_GET_RESULTS_SUCCESS]: (state, { results, teams }) => {
    state.loading = false
    state.ranking =
      results && teams ? formatResultsToRanking(results, teams) : []
  },
  [LEAGUE_RESET_STATE]: (state) => {
    state.loading = false
    state.error = null
    state.createdLeague = null
    state.updatedLeague = null
    state.groupDetails = {}
  }
}

const getLineupIdsFromMatches = (matches) => {
  return matches.reduce(
    (teamIds, match) => [
      ...teamIds,
      ...(!teamIds.includes(match.home_id) && [match.home_id]),
      ...(!teamIds.includes(match.away_id) && [match.away_id])
    ],
    []
  )
}

const formatMatches = (matches) => {
  const formatted = matches.reduce((allMatches, match) => {
    const dateId = match.date.id
    const eventIdExists = !!allMatches[dateId]
    return {
      ...allMatches,
      [dateId]: {
        ...allMatches[dateId],
        ...(!eventIdExists && {
          dateId,
          date: match.date.startDateTime,
          eventDate: formatDate.long(match.date.startDateTime),
          eventTitle: match.date.event.name,
          eventId: match.date.event.id
        }),
        matches: [
          ...(eventIdExists && [...allMatches[dateId].matches]),
          {
            id: match.id,
            away_id: match.away_id,
            away_score: match.away_score,
            home_id: match.home_id,
            home_score: match.home_score
          }
        ]
      }
    }
  }, {})
  const sorted = Object.keys(formatted)
    .map((key) => formatted[key])
    .sort((a, b) => moment(a.date) - moment(b.date))
  return sorted.reduce((result, item) => {
    return {
      ...result,
      [item.dateId]: item
    }
  }, {})
}

const formatRanking = (ranking, teams) => {
  const teamRanking = ranking && ranking.team_ranking
  return teamRanking
    ? Object.keys(teamRanking)
        .map((team) => ({
          id: team,
          team: teams[team],
          points: teamRanking[team].reduce(
            (points, item) => (points += item.points),
            0
          )
        }))
        .sort((a, b) => b.points - a.points)
    : []
}

const getTeamIdsFromResults = (results) =>
  results.reduce((teamIds, result) => {
    result.points.forEach((team) => {
      teamIds = [
        ...teamIds,
        ...(!teamIds.includes(team.teamId) && [team.teamId])
      ]
    })
    return teamIds
  }, [])

const formatResultsToRanking = (results, teams) => {
  const teamRanking = results.reduce((ranking, result) => {
    result.points.forEach((team) => {
      const index = ranking.findIndex((rank) => rank.id === team.teamId)
      ranking = [
        ...ranking.filter((rank) => rank.id !== team.teamId),
        index !== -1
          ? {
              ...ranking[index],
              points: ranking[index].points + team.points
            }
          : {
              id: team.teamId,
              name: teams[team.teamId],
              points: team.points
            }
      ]
    })
    return ranking
  }, [])
  return teamRanking.sort((a, b) => b.points - a.points)
}

export default {
  state,
  getters,
  actions,
  mutations
}
