export const EVENT_UPDATE_LOCATION = 'EVENT_UPDATE_LOCATION'
export const LOCATION_REQUEST = 'LOCATION_REQUEST'
export const LOCATION_REQUEST_SUCCESS = 'LOCATION_REQUEST_SUCCESS'
export const LOCATION_ERROR = 'LOCATION_ERROR'
export const LOCATION_LIST = 'LOCATION_LIST'
export const LOCATION_GET_BY_ID = 'LOCATION_GET_BY_ID'
export const LOCATION_GET_BY_ID_SUCCESS = 'LOCATION_GET_BY_ID_SUCCESS'
export const LOCATION_REMOVE_BY_ID = 'LOCATION_REMOVE_BY_ID'
export const LOCATION_REMOVE_BY_ID_SUCCESS = 'LOCATION_REMOVE_BY_ID_SUCCESS'
export const LOCATION_LIST_SUCCESS = 'LOCATION_LIST_SUCCESS'
export const LOCATION_VIEW = 'LOCATION_VIEW'
export const LOCATION_VIEW_SUCCESS = 'LOCATION_VIEW_SUCCESS'
export const LOCATION_CITY_LIST = 'LOCATION_CITY_LIST'
export const LOCATION_CITY_LIST_SUCCESS = 'LOCATION_CITY_LIST_SUCCESS'
export const LOCATION_CREATE = 'LOCATION_CREATE'
export const LOCATION_ADDRESS_COORDINATES = 'LOCATION_ADDRESS_COORDINATES'
export const LOCATION_ADDRESS_COORDINATES_SUCCESS =
  'LOCATION_ADDRESS_COORDINATES_SUCCESS'
export const LOCATION_TYPED_LOCATIONS = 'LOCATION_TYPED_LOCATIONS'
export const LOCATION_TYPED_LOCATIONS_SUCCESS =
  'LOCATION_TYPED_LOCATIONS_SUCCESS'
export const LOCATION_TYPES_LOCATIONS_RESET = 'LOCATION_TYPES_LOCATIONS_RESET'
export const LOCATION_TYPES_LOCATIONS_RESET_SUCCESS =
  'LOCATION_TYPES_LOCATIONS_RESET_SUCCESS'
export const LOCATION_MAKE_RESERVATION = 'LOCATION_MAKE_RESERVATION'
export const LOCATION_MAKE_RESERVATION_SUCCESS =
  'LOCATION_MAKE_RESERVATION_SUCCESS'
export const LOCATION_BOOK = 'LOCATION_BOOK'
export const LOCATION_BOOK_SUCCESS = 'LOCATION_BOOK_SUCCESS'
export const LOCATION_BY_USER_ID = 'LOCATION_BY_USER_ID'
export const LOCATION_BY_USER_ID_SUCCESS = 'LOCATION_BY_USER_ID_SUCCESS'

export const LOCATION_GET_COMMERCIAL = 'LOCATION_GET_COMMERCIAL'
export const LOCATION_GET_COMMERCIAL_SUCCESS = 'LOCATION_GET_COMMERCIAL_SUCCESS'
export const LOCATION_GET_COMMERCIAL_BY_SLUG = 'LOCATION_GET_COMMERCIAL_BY_SLUG'
export const LOCATION_GET_COMMERCIAL_BY_SLUG_SUCCESS =
  'LOCATION_GET_COMMERCIAL_BY_SLUG_SUCCESS'
export const LOCATION_BOOK_COMMERCIAL = 'LOCATION_BOOK_COMMERCIAL'
export const LOCATION_GET_COMMERCIAL_AVAILABILITY =
  'LOCATION_GET_COMMERCIAL_AVAILABILITY'
export const LOCATION_GET_COMMERCIAL_AVAILABILITY_SUCCESS =
  'LOCATION_GET_COMMERCIAL_AVAILABILITY_SUCCESS'
