import 'mutationobserver-shim'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
// import './registerServiceWorker' // disabled service worker
import router from './router'
import store from './store'
import i18n from './i18n'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { setAuthToken } from './utils/api'
import VModal from 'vue-js-modal'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import config from './utils/config'
import moment from 'moment'
import { Settings } from 'luxon'
import VueGtag from 'vue-gtag'
import './assets/tailwind.css'

const token = localStorage.getItem('auth-token')
if (token) {
  setAuthToken(token)
}

window.commitRef = config.commitRef
window.cachedCommitRef = config.cachedCommitRef
window.buildId = config.buildId

moment.locale('nl')
Settings.defaultLocale = 'nl'
Settings.defaultZoneName = 'Europe/Amsterdam'

if (process.env.NODE_ENV !== 'development') {
  // setup sentry tracking
  Sentry.init({
    Vue,
    dsn:
      'https://46e1f17a48244f6a903fff4cffe2a745@o508340.ingest.sentry.io/5600705',
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: process.env.NODE_ENV,
    release: `invi-frontend@${process.env.VUE_APP_COMMIT_REF}`
  })
}

Vue.config.productionTip = false

Vue.component('datetime', Datetime)
Vue.use(VueMeta)
Vue.use(VModal, { dialog: true })
Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey
    // libraries: 'places'
  }
})

Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-YY7DFNMKHW'
    },
    bootstrap: true,
    enabled: process.env.NODE_ENV === 'production'
  },
  router
)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
