import {
  OPTION_ERROR,
  OPTION_REQUEST,
  OPTION_GET,
  OPTION_GET_SUCCESS
} from '../actions/option'

import api from '@/utils/api'

const state = {
  loading: false,
  error: null,
  options: []
}

const getters = { maintenanceMode: (state) => state.maintenanceMode }

const actions = {
  [OPTION_GET]: async ({ commit }, optionName) => {
    commit(OPTION_REQUEST)
    try {
      const resp = await api.get('option', {
        params: {
          filter: {
            where: {
              optionName
            },
            limit: 1
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(OPTION_GET_SUCCESS, {
          name: optionName,
          value: parseFloat(resp.data[0].optionValue)
        })
      } else {
        commit(OPTION_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(OPTION_ERROR, `Something went wrong: ${err}`)
    }
  }
}

const mutations = {
  [OPTION_REQUEST]: (state) => {
    state.loading = true
  },
  [OPTION_ERROR]: (state, errorMessage) => {
    state.loading = false
    state.error = errorMessage
  },
  [OPTION_GET_SUCCESS]: (state, option) => {
    state.loading = false
    state.options = { ...state.options, [option.name]: option.value }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
