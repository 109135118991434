import {
  STATISTICS_REQUEST,
  STATISTICS_LIST,
  STATISTICS_ERROR,
  STATISTICS_LIST_SUCCESS
} from '../actions/statistics'
import Vue from 'vue'
// import store from '../../store'
import api from '@/utils/api'
const state = { statistics: null, status: '' }

const getters = {
  getInviplayStatistics: (state) => state.statistics
}

const actions = {
  [STATISTICS_LIST]: ({ commit }) => {
    commit(STATISTICS_REQUEST)
    api
      .post('location/statistics')
      .then((res) => {
        commit(STATISTICS_LIST_SUCCESS, res.data)
      })
      .catch((err) => {
        commit(STATISTICS_ERROR, err)
      })
  }
}

const mutations = {
  [STATISTICS_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [STATISTICS_LIST_SUCCESS]: (state, res) => {
    state.status = 'success'
    Vue.set(state, 'statistics', res)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
