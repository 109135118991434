export const PARTICIPANT_ADD_ACTIVITY = 'PARTICIPANT_ADD_ACTIVITY'
export const PARTICIPANT_AVATAR_UPLOAD = 'PARTICIPANT_AVATAR_UPLOAD'
export const PARTICIPANT_AVATAR_UPLOAD_SUCCESS =
  'PARTICIPANT_AVATAR_UPLOAD_SUCCESS'
export const PARTICIPANT_CLEAR = 'PARTICIPANT_CLEAR'
export const PARTICIPANT_CREATE = 'PARTICIPANT_CREATE'
export const PARTICIPANT_CREATE_SUCCESS = 'PARTICIPANT_CREATE_SUCCESS'
export const PARTICIPANT_ERROR = 'PARTICIPANT_ERROR'
export const PARTICIPANT_GET_BY_IDS = 'PARTICIPANT_GET_BY_IDS'
export const PARTICIPANT_GET_BY_IDS_SUCCESS = 'PARTICIPANT_GET_BY_IDS_SUCCESS'
export const PARTICIPANT_OWN = 'PARTICIPANT_OWN'
export const PARTICIPANT_OWN_SUCCESS = 'PARTICIPANT_OWN_SUCCESS'
export const PARTICIPANT_PASSWORD_RESET = 'PARTICIPANT_PASSWORD_RESET'
export const PARTICIPANT_PASSWORD_RESET_REQUEST =
  'PARTICIPANT_PASSWORD_RESET_REQUEST'
export const PARTICIPANT_REQUEST = 'PARTICIPANT_REQUEST'
export const PARTICIPANT_SEARCH = 'PARTICIPANT_SEARCH'
export const PARTICIPANT_SEARCH_CLEAR = 'PARTICIPANT_SEARCH_CLEAR'
export const PARTICIPANT_SEARCH_SUCCESS = 'PARTICIPANT_SEARCH_SUCCESS'
export const PARTICIPANT_STORE_IP = 'PARTICIPANT_STORE_IP'
export const PARTICIPANT_STORE_IP_SUCCESS = 'PARTICIPANT_STORE_IP_SUCCESS'
export const PARTICIPANT_UPDATE = 'PARTICIPANT_UPDATE'
export const PARTICIPANT_UPDATE_ACTIVITY = 'PARTICIPANT_UPDATE_ACTIVITY'
export const PARTICIPANT_UPDATE_BANKACCOUNT = 'PARTICIPANT_UPDATE_BANKACCOUNT'
export const PARTICIPANT_UPDATE_BANKACCOUNT_SUCCESS =
  'PARTICIPANT_UPDATE_BANKACCOUNT_SUCCESS'
export const PARTICIPANT_UPDATE_PHONENUMBER = 'PARTICIPANT_UPDATE_PHONENUMBER'
export const PARTICIPANT_UPDATE_PHONENUMBER_SUCCESS =
  'PARTICIPANT_UPDATE_PHONENUMBER_SUCCESS'
export const PARTICIPANT_UPDATE_SUCCESS = 'PARTICIPANT_UPDATE_SUCCESS'
export const PARTICIPANT_VIEW = 'PARTICIPANT_VIEW'
export const PARTICIPANT_VIEW_SUCCESS = 'PARTICIPANT_VIEW_SUCCESS'
export const PARTICIPANT_GET_CREDITCARD_ON_EVENT =
  'PARTICIPANT_GET_CREDITCARD_ON_EVENT'
export const PARTICIPANT_GET_CREDITCARD_ON_EVENT_SUCCESS =
  'PARTICIPANT_GET_CREDITCARD_ON_EVENT_SUCCESS'
export const PARTICIPANT_GET_CREDITCARDS = 'PARTICIPANT_GET_CREDITCARDS'
export const PARTICIPANT_GET_CREDITCARDS_SUCCESS =
  'PARTICIPANT_GET_CREDITCARDS_SUCCESS'
export const PARTICIPANT_GET_GROUPS_OWNED = 'PARTICIPANT_GET_GROUPS_OWNED'
export const PARTICIPANT_GET_GROUPS_OWNED_SUCCESS =
  'PARTICIPANT_GET_GROUPS_OWNED_SUCCESS'
