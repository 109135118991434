import Vue from 'vue'
import Vuex from 'vuex'
import activity from './modules/activity'
import auth from './modules/auth'
import comment from './modules/comment'
import creditcard from './modules/creditcard'
import date from './modules/date'
import event from './modules/event'
import eventTypes from './modules/event_type'
import group from './modules/group'
import location from './modules/location'
import league from './modules/league'
import match from './modules/match'
import option from './modules/option'
import participant from './modules/participant'
import participantActivity from './modules/participant_activity'
import participantDate from './modules/participant_date'
import payment from './modules/payment'
import result from './modules/result'
import statistics from './modules/statistics'
import team from './modules/team'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    activity,
    auth,
    comment,
    creditcard,
    date,
    event,
    eventTypes,
    group,
    location,
    league,
    match,
    option,
    participant,
    participantActivity,
    participantDate,
    payment,
    result,
    statistics,
    team
  },
  strict: debug
})
