import {
  PAYMENT_ADD_PAYMENT_NEEDED,
  PAYMENT_ADD_REFUND_NEEDED,
  PAYMENT_GET_METHODS_ERROR,
  PAYMENT_GET_METHODS_SUCCESS,
  PAYMENT_GET_METHODS,
  PAYMENT_GET_STATUS,
  PAYMENT_GET_STATUS_SUCCESS,
  PAYMENT_GET_PAID_ACTIVITIES_ERROR,
  PAYMENT_GET_PAID_ACTIVITIES_SUCCESS,
  PAYMENT_GET_PAID_ACTIVITIES,
  PAYMENT_GET_REFUNDS_SUCCESS,
  PAYMENT_GET_REFUNDS,
  PAYMENT_REMOVE_PAYMENT_NEEDED,
  PAYMENT_REMOVE_REFUND_NEEDED,
  PAYMENT_RESET_STATE,
  PAYMENT_SELECT_BANK,
  PAYMENT_SELECT_METHOD,
  PAYMENT_SET_PARTICIPANT_IS_NEW,
  PAYMENT_SET_TYPE,
  PAYMENT_REQUEST,
  PAYMENT_ERROR,
  PAYMENT_GET_STATUS_BY_ID,
  PAYMENT_GET_STATUS_BY_ID_SUCCESS
} from '../actions/payment'
import Vue from 'vue'
import api from '@/utils/api'

const state = {
  paymentsNeeded:
    // localStorage.getItem('paymentsNeeded') !== null
    // ? JSON.parse(localStorage.getItem('paymentsNeeded'))
    // : [],
    [],
  refundsNeeded:
    // localStorage.getItem('refundsNeeded') !== null
    //   ? JSON.parse(localStorage.getItem('refundsNeeded'))
    //   : [],
    [],
  paymentMethods: [],
  selectedMethod: '',
  selectedBank: '',
  paidActivities: [],
  mollie: {
    status: null,
    redirectUrl: undefined,
    updates: []
  },
  settle: {
    loading: false,
    success: false,
    errorMessage: undefined
  },
  refund: {
    loading: false,
    success: false,
    errorMessage: undefined
  },
  participantIsNew: null,
  loading: false,
  error: '',
  refunds: [],
  type: undefined,
  status: undefined
}

const getters = {
  getSelectedMethod: (state) => state.selectedMethod,
  getSelectedBank: (state) => state.selectedBank
}

const actions = {
  [PAYMENT_GET_METHODS]: ({ commit }) => {
    return api
      .get('payment/methods')
      .then((resp) => {
        if (resp.status === 200 && resp.data) {
          commit(PAYMENT_GET_METHODS_SUCCESS, resp.data)
        }
      })
      .catch((resp) => {
        commit(
          PAYMENT_GET_METHODS_ERROR,
          'Something went wrong: ',
          resp.statusText
        )
      })
  },
  [PAYMENT_GET_PAID_ACTIVITIES]: ({ commit }, data) => {
    if (data && data.length) {
      const participantDateIds = data.map((id) => ({
        participant_dateID: id
      }))
      return api
        .get('participant_date_payment', {
          params: {
            filter: { where: { or: participantDateIds } }
          }
        })
        .then((resp) => {
          if (resp.status === 200 && resp.data) {
            commit(PAYMENT_GET_PAID_ACTIVITIES_SUCCESS, resp.data)
          }
        })
        .catch((err) => {
          commit(
            PAYMENT_GET_PAID_ACTIVITIES_SUCCESS,
            `Something went wrong: ${err}`
          )
        })
    }
    commit(
      PAYMENT_GET_PAID_ACTIVITIES_SUCCESS,
      'No participant_dateids provided'
    )
    return false
  },
  [PAYMENT_GET_REFUNDS]: async ({ commit }, eventId) => {
    commit(PAYMENT_REQUEST)
    try {
      const resp = await api.get(`payment/refunds/${eventId}`)
      if (resp.status === 200 && resp.data) {
        commit(PAYMENT_GET_REFUNDS_SUCCESS, resp.data)
      } else {
        commit(PAYMENT_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (e) {
      commit(PAYMENT_ERROR, 'Something went wrong')
    }
  },
  [PAYMENT_GET_STATUS]: async ({ commit }, { eventId }) => {
    commit(PAYMENT_REQUEST)
    try {
      const resp = await api.get(`payment/status/${eventId}`)
      if (resp.status === 200 && resp.data) {
        commit(PAYMENT_GET_STATUS_SUCCESS, resp.data)
      } else {
        commit(PAYMENT_ERROR, 'Something went wrong: ', resp.statusText)
      }
    } catch (e) {
      commit(PAYMENT_ERROR, 'Something went wrong')
    }
  },
  async [PAYMENT_GET_STATUS_BY_ID]({ commit }, { paymentId }) {
    commit(PAYMENT_REQUEST, {
      action: PAYMENT_GET_STATUS_BY_ID,
      payload: { paymentId }
    })
    try {
      const response = await api.get(`payment/${paymentId}/status`)
      if (response && response.status === 200) {
        commit(PAYMENT_GET_STATUS_BY_ID_SUCCESS, {
          paymentId,
          ...response.data
        })
        return response.data?.status
      } else {
        commit(PAYMENT_ERROR, PAYMENT_GET_STATUS_BY_ID)
      }
    } catch (e) {
      commit(PAYMENT_ERROR, PAYMENT_GET_STATUS_BY_ID)
    }
  }
}

const mutations = {
  [PAYMENT_REQUEST]: (state) => {
    state.loading = true
    state.error = false
  },
  [PAYMENT_ERROR]: (state) => {
    state.loading = false
    state.error = true
  },
  [PAYMENT_ADD_PAYMENT_NEEDED]: (state, id) => {
    const paymentsNeeded = state.paymentsNeeded.includes(id)
      ? state.paymentsNeeded
      : [...state.paymentsNeeded, id]
    state.paymentsNeeded = paymentsNeeded
    // localStorage.setItem('paymentsNeeded', JSON.stringify(paymentsNeeded))
  },
  [PAYMENT_REMOVE_PAYMENT_NEEDED]: (state, id) => {
    const paymentsNeeded = state.paymentsNeeded.filter((item) => item !== id)
    state.paymentsNeeded = paymentsNeeded
    // localStorage.setItem('paymentsNeeded', JSON.stringify(paymentsNeeded))
  },
  [PAYMENT_ADD_REFUND_NEEDED]: (state, id) => {
    const refundsNeeded = state.refundsNeeded.includes(id)
      ? state.refundsNeeded
      : [...state.refundsNeeded, id]
    state.refundsNeeded = refundsNeeded
    // localStorage.setItem('refundsNeeded', JSON.stringify(refundsNeeded))
  },
  [PAYMENT_REMOVE_REFUND_NEEDED]: (state, id) => {
    const refundsNeeded = state.refundsNeeded.filter((item) => item !== id)
    state.refundsNeeded = refundsNeeded
    // localStorage.setItem('refundsNeeded', JSON.stringify(refundsNeeded))
  },
  [PAYMENT_SELECT_METHOD]: (state, method) => {
    state.selectedMethod = method
  },
  [PAYMENT_SELECT_BANK]: (state, bank) => {
    state.selectedBank = bank
  },
  [PAYMENT_REQUEST]: (state) => {
    state.loading = true
    state.error = false
  },
  [PAYMENT_ERROR]: (state, msg) => {
    state.loading = false
    state.error = msg
  },
  [PAYMENT_GET_METHODS_SUCCESS]: (state, resp) => {
    Vue.set(state, 'paymentMethods', resp)
  },
  [PAYMENT_GET_METHODS_ERROR]: (state, msg) => {
    state.error = msg
  },
  [PAYMENT_GET_PAID_ACTIVITIES_SUCCESS]: (state, resp) => {
    Vue.set(state, 'paidActivities', resp)
  },
  [PAYMENT_GET_PAID_ACTIVITIES_ERROR]: (state, msg) => {
    state.error = msg
  },
  [PAYMENT_RESET_STATE]: (state) => {
    // localStorage.removeItem('paymentsNeeded')
    // localStorage.removeItem('refundsNeeded')
    state.paymentsNeeded = []
    state.refundsNeeded = []
    state.settle = {
      loading: false,
      success: false,
      errorMessage: undefined
    }
    state.refund = {
      loading: false,
      success: false,
      errorMessage: undefined
    }
    state.participantIsNew = null
    state.mollie = {
      status: null,
      redirectUrl: undefined,
      updates: []
    }
  },
  [PAYMENT_SET_PARTICIPANT_IS_NEW]: (state, value) => {
    state.participantIsNew = value
  },
  [PAYMENT_GET_REFUNDS_SUCCESS]: (state, data) => {
    state.loading = false
    state.refunds = data
  },
  [PAYMENT_GET_STATUS_SUCCESS]: (state, data) => {
    state.loading = false
    state.mollie.status = data
  },
  [PAYMENT_SET_TYPE]: (state, type) => {
    state.paymentType = type
  },
  [PAYMENT_GET_STATUS_BY_ID_SUCCESS]: (state, { paymentId, status }) => {
    state.loading = false
    state.status = {
      ...state.status,
      [paymentId]: status
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
