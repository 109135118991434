import {
  COMMENT_REQUEST,
  COMMENT_ERROR,
  COMMENT_GET_ALL,
  COMMENT_GET_ALL_SUCCESS,
  COMMENT_CREATE,
  COMMENT_CREATE_SUCCESS
} from '../actions/comment'

import api from '@/utils/api'
import config from '@/utils/config'
import Vue from 'vue'
import moment from 'moment-timezone'

const state = { status: '', commentList: [] }

const getters = {}

const actions = {
  [COMMENT_GET_ALL]: ({ commit }, { eventID, groupID, order = 'ASC' }) => {
    commit(COMMENT_REQUEST)
    return api
      .get('comment', {
        params: {
          filter: {
            where: {
              ...(eventID && { eventID: eventID }),
              ...(groupID && { groupID: groupID })
            },
            order: `created ${order}`,
            include: [
              {
                relation: 'createdBy',
                scope: {
                  fields: ['firstName', 'lastName', 'avatar', 'avatarUrl', 'id']
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(COMMENT_GET_ALL_SUCCESS, resp)
        return true
      })
      .catch((err) => {
        commit(COMMENT_ERROR, err)
      })
  },
  [COMMENT_CREATE]: ({ commit, dispatch }, commentData) => {
    commit(COMMENT_REQUEST)
    api
      .post('comment', {
        comment: commentData.comment,
        ...(commentData.eventID && { eventID: commentData.eventID }),
        ...(commentData.groupID && { groupID: commentData.groupID })
      })
      .then(() => {
        commit(COMMENT_CREATE_SUCCESS)
        dispatch(COMMENT_GET_ALL, {
          ...(commentData.eventID && { eventID: commentData.eventID }),
          ...(commentData.groupID && {
            groupID: commentData.groupID,
            order: 'DESC'
          })
        })
      })
      .catch(() => {
        commit(COMMENT_ERROR)
      })
  }
}

const mutations = {
  [COMMENT_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [COMMENT_GET_ALL_SUCCESS]: (state, resp) => {
    state.status = 'success'
    const commentList = []
    for (const property in resp.data) {
      if (!resp.data.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
      const commentItem = resp.data[property]
      commentItem.createdFormatted = moment(commentItem.created)
        .locale('nl')
        .format('D MMM H:mm')
      commentItem.createdBy.avatarUrl =
        commentItem.createdBy.avatarUrl !== null
          ? config.baseUrl + commentItem.createdBy.avatarUrl
          : null

      commentList.push(commentItem)
    }
    Vue.set(state, 'commentList', commentList)
  },
  [COMMENT_CREATE_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [COMMENT_ERROR]: (state) => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
