export const LEAGUE_CREATE = 'LEAGUE_CREATE'
export const LEAGUE_CREATE_SUCCESS = 'LEAGUE_CREATE_SUCCESS'
export const LEAGUE_ERROR = 'LEAGUE_ERROR'
export const LEAGUE_GET_DETAILS = 'LEAGUE_GET_DETAILS'
export const LEAGUE_GET_DETAILS_SUCCESS = 'LEAGUE_GET_DETAILS_SUCCESS'
export const LEAGUE_GET_RANKING = 'LEAGUE_GET_RANKING'
export const LEAGUE_GET_RANKING_SUCCESS = 'LEAGUE_GET_RANKING_SUCCESS'
export const LEAGUE_GET_RESULTS = 'LEAGUE_GET_RESULTS'
export const LEAGUE_GET_RESULTS_SUCCESS = 'LEAGUE_GET_RESULTS_SUCCESS'
export const LEAGUE_GET_MATCHES = 'LEAGUE_GET_MATCHES'
export const LEAGUE_GET_MATCHES_SUCCESS = 'LEAGUE_GET_MATCHES_SUCCESS'
export const LEAGUE_REQUEST = 'LEAGUE_REQUEST'
export const LEAGUE_RESET_STATE = 'LEAGUE_RESET_STATE'
export const LEAGUE_UPDATE = 'LEAGUE_UPDATE'
export const LEAGUE_UPDATE_SUCCESS = 'LEAGUE_UPDATE_SUCCESS'
