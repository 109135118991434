import {
  ACTIVITY_REQUEST,
  ACTIVITY_ERROR,
  ACTIVITY_LIST,
  ACTIVITY_LIST_SUCCESS
} from '../actions/activity'

import api from '@/utils/api'
import Vue from 'vue'
import _ from 'lodash'

const state = { status: '', activityList: [], activityKeyValueList: [] }
const getters = {}

const actions = {
  async [ACTIVITY_LIST]({ commit }) {
    commit(ACTIVITY_REQUEST)
    try {
      const activityResponse = await api.get('activity_type', {
        params: {
          filter: {
            where: {
              active: true
            }
          }
        }
      })

      if (activityResponse !== undefined) {
        commit(ACTIVITY_LIST_SUCCESS, activityResponse)
        return true
      }
    } catch (e) {
      commit(ACTIVITY_ERROR)
      return false
    }
  }
}

const mutations = {
  [ACTIVITY_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [ACTIVITY_LIST_SUCCESS]: (state, resp) => {
    state.status = 'success'

    // Order by name and append 'overig' in array
    const activityListOrder = _.orderBy(resp.data, ['name'], 'asc')
    const activityListOrderAppendOthers = _.sortBy(
      activityListOrder,
      function (item) {
        return item.name.toLowerCase() === 'overig' ? 1 : 0
      }
    )

    // Order by name and append 'overig' in array Map array to key => value
    const activityKeyValueList = _.map(
      activityListOrderAppendOthers,
      function (item) {
        return { key: item.id, value: item.name, slug: item.slug }
      }
    )

    Vue.set(state, 'activityList', activityListOrderAppendOthers)
    Vue.set(state, 'activityKeyValueList', activityKeyValueList)
  },
  [ACTIVITY_ERROR]: (state) => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
