export const CREDITCARD_REQUEST = 'CREDITCARD_REQUEST'
export const CREDITCARD_ERROR = 'CREDITCARD_ERROR'
export const CREDITCARD_GET_ALL = 'CREDITCARD_GET_ALL'
export const CREDITCARD_GET_ALL_SUCCESS = 'CREDITCARD_GET_ALL_SUCCESS'
export const CREDITCARD_GET_PURCHASED = 'CREDITCARD_GET_PURCHASED'
export const CREDITCARD_GET_PURCHASED_SUCCESS =
  'CREDITCARD_GET_PURCHASED_SUCCESS'
export const CREDITCARD_GET_GROUP = 'CREDITCARD_GET_GROUP'
export const CREDITCARD_GET_GROUP_SUCCESS = 'CREDITCARD_GET_GROUP_SUCCESS'
export const CREDITCARD_GET_ONE = 'CREDITCARD_GET_ONE'
export const CREDITCARD_GET_ONE_SUCCESS = 'CREDITCARD_GET_ONE_SUCCESS'
export const CREDITCARD_PURCHASE = 'CREDITCARD_PURCHASE'
export const CREDITCARD_PURCHASE_SUCCESS = 'CREDITCARD_PURCHASE_SUCCESS'
export const CREDITCARD_NEW_UPDATE = 'CREDITCARD_NEW_UPDATE'
export const CREDITCARD_NEW_STORE = 'CREDITCARD_NEW_STORE'
export const CREDITCARD_NEW_RESET = 'CREDITCARD_NEW_RESET'
export const CREDITCARD_NEW_STORE_SUCCESS = 'CREDITCARD_NEW_STORE_SUCCESS'
export const CREDITCARD_USE_CREDITS = 'CREDITCARD_USE_CREDITS'
export const CREDITCARD_USE_CREDITS_SUCCESS = 'CREDITCARD_USE_CREDITS_SUCCESS'
export const CREDITCARD_UPDATE = 'CREDITCARD_UPDATE'
export const CREDITCARD_UPDATE_SUCCESS = 'CREDITCARD_UPDATE_SUCCESS'
export const CREDITCARD_GET_ALL_OF_USER_ON_LOCATION =
  'CREDITCARD_GET_ALL_OF_USER_ON_LOCATION'
export const CREDITCARD_GET_ALL_OF_USER_ON_LOCATION_SUCCESS =
  'CREDITCARD_GET_ALL_OF_USER_ON_LOCATION_SUCCESS'
