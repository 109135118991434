import { ifAuthenticated } from './guards'
const LocationsOverview = () =>
  import(
    /* webpackChunkName: "locations" */ '@/views/locations/LocationsOverview'
  )
const LocationsBookSport = () =>
  import(
    /* webpackChunkName: "locations" */ '@/views/locations/LocationsBookSport'
  )
const LocationsBookType = () =>
  import(
    /* webpackChunkName: "locations" */ '@/views/locations/LocationsBookType'
  )
// const LocationsBookDate = () =>
//   import(
//     /* webpackChunkName: "locations" */ '@/views/locations/LocationsBookDate'
//   )
const NewBooking = () =>
  import(/* webpackChunkName: "locations" */ '@/views/locations/NewBooking')
const LocationsBookConfirm = () =>
  import(
    /* webpackChunkName: "locations" */ '@/views/locations/LocationsBookConfirm'
  )
const LocationBookingToActivity = () =>
  import(
    /* webpackChunkName: "locations" */ '@/views/locations/LocationBookingToActivity'
  )

export default [
  {
    path: '/locations',
    alias: '/locaties',
    name: 'LocationsOverview',
    component: LocationsOverview
  },
  {
    path: '/locations/:location',
    alias: '/locaties/:location',
    name: 'LocationsOverviewSelected',
    component: LocationsOverview
  },
  {
    path: '/locations/book/sport',
    alias: '/locaties/book/sport',
    name: 'LocationsBookSport',
    component: LocationsBookSport,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/locations/book/type',
    alias: '/locaties/book/type',
    name: 'LocationsBookType',
    component: LocationsBookType,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/locations/:location/book',
    alias: '/locaties/:location/book',
    name: 'NewBooking',
    component: NewBooking,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/locations/book/confirm',
    alias: '/locaties/book/confirm',
    name: 'LocationsBookConfirm',
    component: LocationsBookConfirm,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/locations/book/activity',
    alias: '/locaties/book/activity',
    name: 'LocationBookingToActivity',
    component: LocationBookingToActivity,
    beforeEnter: ifAuthenticated
  }
]
