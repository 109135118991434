import {
  EVENT_TYPE_REQUEST,
  EVENT_TYPE_ERROR,
  EVENT_TYPE_LIST,
  EVENT_TYPE_LIST_SUCCESS
} from '../actions/event_type'
import api from '@/utils/api'
import Vue from 'vue'

const state = { eventTypeList: [] }
const getters = {}

const actions = {
  async [EVENT_TYPE_LIST]({ commit }) {
    commit(EVENT_TYPE_REQUEST)
    try {
      const eventTypeResponse = await api.get('event_type')

      if (eventTypeResponse !== undefined) {
        commit(EVENT_TYPE_LIST_SUCCESS, eventTypeResponse)
      }
    } catch (e) {
      commit(EVENT_TYPE_ERROR)
      return false
    }
  }
}

const mutations = {
  [EVENT_TYPE_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [EVENT_TYPE_LIST_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'eventTypeList', resp.data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
