export default {
  appUrl:
    process.env.VUE_APP_FULL_APP_URL ||
    `${window.location.protocol}//${window.location.host}`,
  baseUrl: process.env.VUE_APP_API_URL,
  bookingServiceUrl: process.env.VUE_APP_BOOKING_SERVICE_URL,
  featureFlags: {
    creditcards: process.env.VUE_APP_FEATURE_FLAG_CREDITCARDS === 'true',
    groupComments: process.env.VUE_APP_FEATURE_FLAG_GROUP_COMMENTS === 'true',
    groups: process.env.VUE_APP_FEATURE_FLAG_GROUPS === 'true',
    payments: process.env.VUE_APP_FEATURE_FLAG_PAYMENTS === 'true',
    teamsAndLeagues:
      process.env.VUE_APP_FEATURE_FLAG_TEAMS_AND_LEAGUES === 'true',
    skipLandingPage: process.env.VUE_APP_SKIP_LANDING_PAGE === 'true'
  },
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  googleMapsBaseUrl: process.env.VUE_APP_GOOGLE_MAPS_API_URL,
  maintenanceMode: process.env.VUE_APP_MAINTENANCE_MODE === 'true',
  storageContainer: process.env.VUE_APP_STORAGE_CONTAINER,
  commitRef: process.env.VUE_APP_COMMIT_REF,
  cachedCommitRef: process.env.VUE_APP_CACHED_COMMIT_REF,
  buildId: process.env.VUE_APP_BUILD_ID,
  applicationInsightsKey: process.env.VUE_APP_INSTRUMENTATION_KEY,
  fbPixelId: process.env.VUE_APP_FACEBOOK_PIXEL_ID,
  fbAccessToken: process.env.VUE_APP_FACEBOOK_ACCESS_TOKEN,
  branch: process.env.VUE_APP_BRANCH,
  isSplitTest: process.env.VUE_APP_BRANCH === 'split-test',
  paymentProvider: process.env.VUE_APP_PAYMENT_PROVIDER || 'mollie',
  oppTestGroup: process.env.VUE_APP_OPP_TEST_GROUP
}
