import {
  PARTICIPANT_DATE_CREATE_SUCCESS,
  PARTICIPANT_DATE_CREATE,
  PARTICIPANT_DATE_DELETE,
  PARTICIPANT_DATE_ERROR,
  PARTICIPANT_DATE_FIND_ONE,
  PARTICIPANT_DATE_REQUEST,
  PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER_SUCCESS,
  PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER,
  PARTICIPANT_DATE_UPDATE_SUCCESS,
  PARTICIPANT_DATE_UPDATE
} from '../actions/participant_date'
import { DATE_UPDATE_ATTENDANCE_SINGLE_ITEM } from '../actions/date'
import api from '@/utils/api'

const state = { status: '' }

const getters = {}

const actions = {
  async [PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER](
    { commit },
    { dateId, participantId }
  ) {
    commit(PARTICIPANT_DATE_REQUEST)
    try {
      const sendEmailResponse = await api.post(
        `participant_date/${dateId}/sendEmailAfterRegister/${participantId}`
      )
      commit(PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER_SUCCESS)
      return sendEmailResponse
    } catch (e) {
      commit(PARTICIPANT_DATE_ERROR)
      return e
    }
  },
  async [PARTICIPANT_DATE_FIND_ONE]({ commit }, data) {
    commit(PARTICIPANT_DATE_REQUEST)
    try {
      return await api.get('participant_date/findOne', {
        params: {
          filter: {
            where: {
              dateID: parseInt(data.dateID),
              participantID: parseInt(data.participantID)
            }
          }
        }
      })
    } catch (e) {
      commit(PARTICIPANT_DATE_ERROR)
      return e
    }
  },
  async [PARTICIPANT_DATE_UPDATE](
    { commit },
    { participantDateId, attendance, updateState = false }
  ) {
    // Will set state.status to loading
    commit(PARTICIPANT_DATE_REQUEST)
    try {
      const participantDateUpdateResponse = await api
        .patch(`participant_date/${participantDateId}`, {
          attendance
        })
        .catch((err) => {
          commit(PARTICIPANT_DATE_ERROR, err)
          // alert('Er is iets misgegaan, u bent mogelijk al aangemeld voor dit event of het event is vol.')
        })
      if (participantDateUpdateResponse !== undefined && updateState) {
        commit(
          DATE_UPDATE_ATTENDANCE_SINGLE_ITEM,
          participantDateUpdateResponse.data
        )
      }
      commit(PARTICIPANT_DATE_UPDATE_SUCCESS)
      return participantDateUpdateResponse
    } catch (e) {
      commit(PARTICIPANT_DATE_ERROR)
      return false
    }
  },
  async [PARTICIPANT_DATE_CREATE]({ commit }, dataData) {
    commit(PARTICIPANT_DATE_REQUEST)

    try {
      const participantDateResponse = await api
        .post('participant_date', {
          dateID: dataData.dateID,
          participantID: dataData.participantID,
          attendance: dataData.attendance
        })
        .then((res) => res)
      // if (participantDateResponse !== undefined) {
      //   dispatch(DATE_ITEM_BY_EVENT_ID, dataData.eventID)
      // }
      return participantDateResponse
    } catch (e) {
      commit(PARTICIPANT_DATE_ERROR)
      return false
    }
  },
  async [PARTICIPANT_DATE_DELETE](
    { commit },
    { participantId, dateId, participantDateId, sendEmail = true }
  ) {
    commit(PARTICIPANT_DATE_REQUEST)
    try {
      const resp = await api.delete('participant_date/' + participantDateId)
      if (resp.status === 200 && resp.data) {
        if (sendEmail) {
          await api.post(
            `participant_date/${dateId}/sendUnregisterEmail/${participantId}`
          )
        }
        return resp
      } else {
        commit(PARTICIPANT_DATE_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_DATE_ERROR)
      return false
    }
  }
}

const mutations = {
  [PARTICIPANT_DATE_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER_SUCCESS]: (state) => {
    state.status = 'loading'
  },
  [PARTICIPANT_DATE_CREATE_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [PARTICIPANT_DATE_ERROR]: (state) => {
    state.status = 'error'
  },
  [PARTICIPANT_DATE_UPDATE_SUCCESS]: (state) => {
    state.status = 'success'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
