import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '@/utils/config'
import { ifNotAuthenticated, ifAuthenticated } from './guards'
import groups from './groups'
import locations from './locations'
import organize from './organize'
import seo from './seo'
import products from './products'
// import blogs from './blogs'

// temporarly silence errors of failed navigation actions
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const creditcardsEnabled = config.featureFlags.creditcards
const paymentProvider = config.paymentProvider

const ConfirmEmail = () =>
  import(/* webpackChunkName: "account" */ '@/views/ConfirmEmail')
const VerifyEmail = () =>
  import(/* webpackChunkName: "account" */ '@/views/VerifyEmail')
const DeactivateAccount = () =>
  import(/* webpackChunkName: "account" */ '@/views/DeactivateAccount')
const EventAllDates = () =>
  import(/* webpackChunkName: "event" */ '@/views/event/EventAllDates')
const EventSignup = () =>
  import(/* webpackChunkName: "event" */ '@/views/event/EventSignup')
const EventPayment = () =>
  import(/* webpackChunkName: "event" */ '@/views/event/EventPayment')
const EventEdit = () =>
  import(/* webpackChunkName: "event" */ '@/views/event-edit/EventEdit')
const EventEditLoad = () =>
  import(/* webpackChunkName: "event" */ '@/views/event/EventEditLoad')
const EventListOwn = () =>
  import(/* webpackChunkName: "event" */ '@/views/event/EventListOwn')
const EventParticipantAttendance = () =>
  import(
    /* webpackChunkName: "event" */ '@/views/event/EventParticipantAttendance'
  )
const EventView = () =>
  import(/* webpackChunkName: "event" */ '@/views/event/EventView')
const Referral = () => import(/* webpackChunkName: "main" */ '@/views/Referral')
const Feedback = () => import(/* webpackChunkName: "main" */ '@/views/Feedback')
const IDCheck = () => import(/* webpackChunkName: "main" */ '@/views/IDCheck')
const Settlements = () =>
  import(/* webpackChunkName: "main" */ '@/views/profile/Settlements')
const SettlementDetails = () =>
  import(/* webpackChunkName: "main" */ '@/views/profile/SettlementDetails')
const BookingDetails = () =>
  import(/* webpackChunkName: "main" */ '@/views/profile/BookingDetails')
const LandingPage = () =>
  import(/* webpackChunkName: "main" */ '@/views/LandingPage')
const Login = () => import(/* webpackChunkName: "account" */ '@/views/Login')
const Maintenance = () =>
  import(/* webpackChunkName: "main" */ '@/views/Maintenance')
const NotFound = () => import(/* webpackChunkName: "main" */ '@/views/NotFound')
const PasswordForgot = () =>
  import(/* webpackChunkName: "account" */ '@/views/PasswordForgot')
const PasswordReset = () =>
  import(/* webpackChunkName: "account" */ '@/views/PasswordReset')
const SetExternalPassword = () =>
  import(/* webpackChunkName: "account" */ '@/views/SetExternalPassword')
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "account" */ '@/views/PrivacyPolicy')
const ProfileEdit = () =>
  import(/* webpackChunkName: "account" */ '@/views/ProfileEdit')
const ProfileCards = () =>
  import(/* webpackChunkName: "account" */ '@/views/ProfileCards')
const ProfileOwn = () =>
  import(/* webpackChunkName: "account" */ '@/views/ProfileOwn')
const ProfileView = () =>
  import(/* webpackChunkName: "account" */ '@/views/ProfileView')
const RegisterInfo = () =>
  import(/* webpackChunkName: "register" */ '@/views/RegisterInfo')
const RegisterPreferences = () =>
  import(/* webpackChunkName: "register" */ '@/views/RegisterPreferences')
const TermsAndConditions = () =>
  import(/* webpackChunkName: "account" */ '@/views/TermsAndConditions')
const ThankYouPage = () =>
  import(/* webpackChunkName: "event" */ '@/views/ThankYouPage')
const EventSearchList = () =>
  import(/* webpackChunkName: "events" */ '@/views/event/EventSearchList')
const EventsAndCardsOverview = () =>
  import(
    /* webpackChunkName: "events" */ '@/views/event/EventsAndCardsOverview'
  )
const CreditcardDetailPage = () =>
  import(/* webpackChunkName: "cards" */ '@/views/cards/CreditcardDetailPage')
const CreditcardPayment = () =>
  import(/* webpackChunkName: "cards" */ '@/views/cards/CreditcardPayment')
const CreditcardConfirmation = () =>
  import(/* webpackChunkName: "cards" */ '@/views/cards/CreditcardConfirmation')
const CreditcardNew = () =>
  import(/* webpackChunkName: "cards" */ '@/views/cards/CreditcardNew')
const CreditcardSelectEvents = () =>
  import(/* webpackChunkName: "cards" */ '@/views/cards/CreditcardSelectEvents')
const CreditcardReady = () =>
  import(/* webpackChunkName: "cards" */ '@/views/cards/CreditcardReady')

Vue.use(VueRouter)

const routes = [
  ...(config.maintenanceMode
    ? [
        {
          path: '/*',
          name: 'Maintenance',
          component: Maintenance
        }
      ]
    : []),
  ...organize,
  ...groups,
  ...locations,
  ...seo,
  ...products,
  // ...blogs,
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: ConfirmEmail
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/register-info',
    name: 'Register',
    component: RegisterInfo,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/register-preferences',
    alias: '/start',
    name: 'RegisterPreferences',
    component: RegisterPreferences,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/password-forgot',
    name: 'PasswordForgot',
    component: PasswordForgot,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/set-password',
    name: 'SetExternalPassword',
    component: SetExternalPassword,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/event-list-own/:groupId?',
    name: 'EventListOwn',
    component: EventListOwn,
    beforeEnter: ifAuthenticated
  },
  // Event edit start
  {
    path: '/event-edit/:id/:securityHash',
    name: 'EventEdit',
    component: EventEdit
  },
  {
    path: '/event-edit/:id/load',
    name: 'EventEditLoad',
    component: EventEditLoad
  },
  // {
  //   path: '/event-edit/:id/location-options',
  //   name: 'EditLocationOptions',
  //   component: organize.OrganizeLocationOptions,
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: '/event-edit/:id/location-map',
  //   name: 'EditLocationMap',
  //   component: organize.OrganizeLocationMap,
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: '/event-edit/:id/location-list',
  //   name: 'EditLocationList',
  //   component: organize.OrganizeLocationList,
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: '/event-edit/:id/location-details/:locationID',
  //   name: 'EditLocationDetails',
  //   component: organize.OrganizeLocationDetails,
  //   beforeEnter: ifAuthenticated,
  // },
  // Event edit end
  {
    path: '/event-view/:id/:dateId?/:securityHash/participants',
    name: 'EventParticipantAttendance',
    component: EventParticipantAttendance,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/event-view/:eventId/:dateId?/:securityHash',
    alias: '/event/:eventId/:securityHash/:name',
    name: 'EventView',
    component: EventView
  },
  {
    path: '/events/:dateId/:securityHash/participants',
    name: 'EventDateAttendance',
    component: EventParticipantAttendance,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/:dateId/:securityHash',
    name: 'EventDate',
    component: EventView
  },
  {
    path: '/events/proposal/:eventId/:securityHash',
    name: 'EventProposal',
    component: EventView
  },
  {
    path: '/events/:dateId?/all-dates/:securityHash',
    name: 'EventAllDates',
    component: EventAllDates
  },
  {
    path: '/events/:dateId?/signup/:securityHash',
    name: 'EventSignup',
    component: EventSignup,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/events/:dateId?/signup/:securityHash/payment',
    name: 'EventPayment',
    component: EventPayment,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/',
    name: 'LandingPage',
    alias: '/index.html',
    component: LandingPage
  },
  {
    path: '/search-list',
    redirect: '/events'
  },
  {
    path: '/events/:activity?',
    name: 'EventOverview',
    component: creditcardsEnabled ? EventsAndCardsOverview : EventSearchList
  },
  ...(creditcardsEnabled
    ? [
        {
          path: '/cards',
          name: 'CreditcardsOverview',
          component: EventsAndCardsOverview
        },
        {
          path: '/cards/confirmation',
          name: 'CreditcardConfirmation',
          component: CreditcardConfirmation
        },
        {
          path: '/cards/new/info',
          alias: '/cards/new',
          name: 'CreditcardNew',
          component: CreditcardNew,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/cards/new/events',
          name: 'CreditcardSelectEvents',
          component: CreditcardSelectEvents,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/cards/new/ready',
          name: 'CreditcardReady',
          component: CreditcardReady,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/cards/:id',
          name: 'CreditcardDetailPage',
          component: CreditcardDetailPage
        },
        {
          path: '/cards/:id/purchase',
          name: 'CreditcardPayment',
          component: CreditcardPayment,
          beforeEnter: ifAuthenticated
        }
      ]
    : []),
  { path: '/referral/:referralUid', name: 'Referral', component: Referral },
  {
    path: '/profile-own',
    name: 'ProfileOwn',
    component: ProfileOwn,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/profile-edit',
    name: 'ProfileEdit',
    component: ProfileEdit,
    beforeEnter: ifAuthenticated
  },
  { path: '/profile-view/:id', name: 'ProfileView', component: ProfileView },
  ...(creditcardsEnabled
    ? [
        {
          path: '/profile/cards',
          name: 'ProfileCards',
          component: ProfileCards,
          beforeEnter: ifAuthenticated
        }
      ]
    : []),
  ...(paymentProvider === 'opp'
    ? [
        {
          path: '/profile/id-check',
          name: 'IDCheck',
          component: IDCheck,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/profile/settlements',
          name: 'Settlements',
          component: Settlements,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/profile/settlements/:settlementUid/:specificationUid',
          name: 'SettlementDetails',
          component: SettlementDetails,
          beforeEnter: ifAuthenticated
        }
      ]
    : []),
  {
    path: '/profile/bookings/:bookingId/:hash',
    name: 'BookingDetails',
    component: BookingDetails
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-Conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/deactivate-account',
    name: 'DeactivateAccount',
    component: DeactivateAccount
  },
  {
    path: '/thanks/:action/:eventId/:dateId?/:securityHash',
    name: 'ThankYouPage',
    component: ThankYouPage
  },
  {
    path: '/utrecht/:activity?',
    name: 'EventsAndCardsOverview',
    component: EventsAndCardsOverview,
    props: {
      city: 'Utrecht'
    }
  },
  {
    path: '/rotterdam/:activity?',
    name: 'EventsAndCardsOverviewRotterdam',
    component: EventsAndCardsOverview,
    props: {
      city: 'Rotterdam'
    }
  },
  {
    path: '/arnhem/:activity?',
    name: 'EventsAndCardsOverviewArnhem',
    component: EventsAndCardsOverview,
    props: {
      city: 'Arnhem'
    }
  },
  {
    path: '/amsterdam/:activity?',
    name: 'EventsAndCardsOverviewAmsterdam',
    component: EventsAndCardsOverview,
    props: {
      city: 'Amsterdam'
    }
  },
  {
    path: '/ede/:activity?',
    name: 'EventsAndCardsOverviewEde',
    component: EventsAndCardsOverview,
    props: {
      city: 'Ede'
    }
  },
  {
    path: '/amersfoort/:activity?',
    name: 'EventsAndCardsOverviewAmersfoort',
    component: EventsAndCardsOverview,
    props: {
      city: 'Amersfoort'
    }
  },
  // fallback route
  {
    path: '/*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
