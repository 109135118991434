export const PARTICIPANT_DATE_REQUEST = 'PARTICIPANT_DATE_REQUEST'
export const PARTICIPANT_DATE_ERROR = 'PARTICIPANT_DATE_ERROR'
export const PARTICIPANT_DATE_CREATE = 'PARTICIPANT_DATE_CREATE'
export const PARTICIPANT_DATE_CREATE_SUCCESS = 'PARTICIPANT_DATE_CREATE_SUCCESS'
export const PARTICIPANT_DATE_FIND_ONE = 'PARTICIPANT_DATE_FIND_ONE'
export const PARTICIPANT_DATE_DELETE = 'PARTICIPANT_DATE_DELETE'
export const PARTICIPANT_DATE_UPDATE = 'PARTICIPANT_DATE_UPDATE'
export const PARTICIPANT_DATE_UPDATE_SUCCESS = 'PARTICIPANT_DATE_UPDATE_SUCCESS'
export const PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER =
  'PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER'
export const PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER_SUCCESS =
  'PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER_SUCCESS'
