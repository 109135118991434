import {
  EVENT_REQUEST,
  EVENT_ERROR,
  EVENT_STATUS_EMPTY,
  EVENT_BY_ID_LIST,
  EVENT_BY_ID_LIST_SUCCESS,
  EVENT_SIGNED_UP_LIST,
  EVENT_SIGNED_UP_LIST_SUCCESS,
  EVENT_OWNED_LIST,
  EVENT_OWNED_LIST_SUCCESS,
  EVENT_CONVERT_LIST_ITEM,
  EVENT_SET_FILTER,
  EVENT_SET_FILTER_SUCCESS,
  EVENT_SELECT,
  EVENT_SELECT_SUCCESS,
  EVENT_DESELECT,
  EVENT_SET_LOCATION_CITY_LIST,
  EVENT_ITEM_SET,
  EVENT_ITEM_SET_EVENT_TYPE,
  EVENT_ITEM_SET_ACTIVITY,
  EVENT_ITEM_SET_WHEN,
  EVENT_ITEM_SET_LOCATION,
  EVENT_ITEM_SET_COMMERCIAL_LOCATION,
  EVENT_ITEM_SET_INFO,
  EVENT_ITEM_SET_COSTS,
  EVENT_CREATE,
  EVENT_CREATE_SUCCESS,
  EVENT_CREATE_UPLOAD_SUCCESS,
  EVENT_EDIT,
  EVENT_EDIT_SUCCESS,
  EVENT_EDIT_REQUEST_COMPLETED,
  EVENT_VIEW,
  EVENT_VIEW_SUCCESS,
  EVENT_VIEW_REQUEST_COMPLETED,
  EVENT_DELETE,
  EVENT_DELETE_SUCCESS,
  EVENT_UPDATE,
  EVENT_EDIT_UPLOAD_SUCCESS,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_SET_LOCATION,
  EVENT_TYPE_SET_FILTER,
  EVENT_PARTICIPANT_ATTENDANCE,
  EVENT_PARTICIPANT_ATTENDANCE_SUCCESS,
  EVENT_ITEM_SET_GROUP,
  EVENT_ITEM_UPDATE,
  EVENT_UPDATE_SINGLE,
  EVENT_UPDATE_SINGLE_SUCCESS,
  EVENT_UPDATE_IMAGE,
  EVENT_UPDATE_IMAGE_SUCCESS,
  EVENT_CREATE_RESET,
  EVENT_LOGGED_IN_USER_ATTENDANCE,
  EVENT_LOGGED_IN_USER_ATTENDANCE_SUCCESS,
  EVENT_CREATED_BY_PARTICIPANT,
  EVENT_CREATED_BY_PARTICIPANT_SUCCESS,
  EVENT_STORE_DATES_TO_UPDATE,
  EVENT_GET_CREDITCARDS,
  EVENT_GET_CREDITCARDS_SUCCESS,
  EVENT_UPDATE_ATTENDANCE,
  EVENT_UPDATE_ATTENDANCE_SUCCESS
  // EVENT_TYPE_SET_FILTER_SUCCESS
} from '../actions/event'
import api from '@/utils/api'
import config from '@/utils/config'
import Vue from 'vue'
import moment from 'moment'
import { DateTime } from 'luxon'
import _ from 'lodash'
import store from '../index'
import isDefinedType from 'is-defined-type'
import noImage from '@/assets/images/img_no-image.png'
import '@/assets/js/photoRotationAdjust'
// import participant from './participant'
// import {itemFormatter} from './date'
import { getImageUrl, formatDate } from '../modules/group'

const state = {
  attendance: [],
  creditcards: {},
  status: '',
  selectedEvent: null,
  datesToUpdate: [],
  eventView: null,
  eventViewRequestCompleted: false,
  eventUpcomingRawList: [],
  eventOwnedList: [],
  eventOwnedPast: [],
  eventOwnedFuture: [],
  eventSignedUpList: [],
  locationCityList: [],
  filterSelectedLocation: '',
  filterSelectedActivity: '',
  filterSelectedEventType: '',
  filterSelectedEventTypeArray: [],
  filterSelectedDay: '',
  eventUpdateItem: null,
  eventTypes: [],
  eventItem:
    localStorage.getItem('eventItemCreate') !== null
      ? JSON.parse(localStorage.getItem('eventItemCreate'))
      : {
          activityID: null,
          commercialLocation: null,
          cost: null,
          description: null,
          endDate: null,
          endTime: null,
          eventType: null,
          eventTypeID: null,
          gender: 'both',
          groupId: null,
          hasCosts: 'no',
          image: null,
          imageUrl: null,
          inviteAll: true,
          inviteGroup: null,
          isRecurring: false,
          locationID: null,
          maximumParticipants: null,
          name: null,
          privateEvent: false,
          recurring: 'none',
          selectedGroupId: null,
          selectedGroupName: null,
          startDate: null,
          startTime: null,
          utcOffset: moment().utcOffset()
        },
  eventWithParticipantAttendanceList: [],
  eventsCreatedByParticipant: []
}

const getters = {
  getFilterSelectedLocation: (state) => state.filterSelectedLocation,
  getFilterSelectedActivity: (state) => state.filterSelectedActivity,
  getFilterSelectedDay: (state) => state.filterSelectedDay,
  createdEvents: (state) => state.eventsCreatedByParticipant,
  createdEventsinPast: (state) =>
    state.eventsCreatedByParticipant
      .reduce((result, event) => {
        return [
          ...result,
          ...event.dates
            .filter(
              (date) => DateTime.fromISO(date.endDateTime) < DateTime.local()
            )
            .map((date) => ({
              activityIcon: event.activity_type.icon,
              id: event.id,
              dateId: date.id,
              name: event.name,
              costFeeIncluded: event.costFeeIncluded,
              securityHash: event.securityHash,
              startDateTime: date.startDateTime,
              endDateTime: date.endDateTime,
              locationName: event.location ? event.location.name : '',
              maximumParticipants: event.maximumParticipants,
              participantCount: date.participantCount,
              image: event.image,
              date: formatDate.short(date.startDateTime),
              endTime: formatDate.time(date.endDateTime),
              startTime: formatDate.time(date.startDateTime)
            }))
        ]
      }, [])
      .sort((a, b) =>
        DateTime.fromISO(a.endDateTime) < DateTime.fromISO(b.endDateTime)
          ? 1
          : -1
      ),

  // .filter((date) => DateTime.fromISO(date.startDateTime) > DateTime.local())
  // .map((date) => ({
  //   name: event.name,
  //   startDateTime: date.startDateTime,
  //   endDateTime: date.endDateTime,
  //   costFeeIncluded: event.costFeeIncluded,
  //   id: event.id,
  //   dateId: date.id,
  //   maximumParticipants: event.maximumParticipants,
  //   participantCount: date.participantCount,
  //   securityHash: event.securityHash,
  //   imageUrl: event.image
  // }))

  createdUpcomingEvents: (state) =>
    state.eventsCreatedByParticipant.filter((event) => event.nextDate)
}

const actions = {
  [EVENT_PARTICIPANT_ATTENDANCE]: ({ commit }, { eventId, securityHash }) => {
    commit(EVENT_REQUEST)
    api
      .post(`date/${eventId}/participantsAttendance`, {
        params: {
          securityHash
        }
      })
      .then((res) => {
        commit(EVENT_PARTICIPANT_ATTENDANCE_SUCCESS, res.data)
      })
      .catch((res) => {
        commit(EVENT_ERROR, res)
      })
  },
  [EVENT_BY_ID_LIST]: ({ commit, dispatch }) => {
    commit(EVENT_REQUEST)
    return api
      .post('date/upcomingEventsList')
      .then((resp) => {
        commit(EVENT_BY_ID_LIST_SUCCESS, resp.data)
        commit(EVENT_CONVERT_LIST_ITEM)
        commit(EVENT_SET_LOCATION_CITY_LIST)
        /*
        Value of 1 and 3 is dispatched in order to set the default filtering to 1 (holidaymakers) and 3 (association)
         */
        dispatch(EVENT_SET_FILTER, { type: 'eventType', value: 1 })
        dispatch(EVENT_SET_FILTER, { type: 'eventType', value: 3 })
        dispatch(EVENT_SET_FILTER, { type: 'eventType', value: 2 })

        return resp
      })
      .catch(() => {
        commit(EVENT_ERROR)
      })
  },
  [EVENT_SIGNED_UP_LIST]: ({ commit }) => {
    commit(EVENT_REQUEST)
    api
      .get(`participant/${store.getters.authId}/dates`, {
        params: {
          filter: {
            where: {
              startDateTime: {
                gte: DateTime.local().toJSDate()
              }
            },
            order: 'startDateTime ASC',
            include: [
              {
                relation: 'participants',
                scope: {
                  fields: ['id']
                }
              },
              {
                relation: 'participant_dates',
                scope: {
                  fields: ['participantID', 'attendance']
                }
              },
              {
                relation: 'event',
                scope: {
                  where: {
                    cancelled: {
                      neq: true
                    }
                  },
                  include: [
                    {
                      relation: 'activity_type'
                    },
                    {
                      relation: 'location',
                      scope: {
                        fields: ['name', 'cost', 'city', 'postalCode', 'adress']
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(EVENT_SIGNED_UP_LIST_SUCCESS, resp)
        return resp
      })
      .catch(() => {
        commit(EVENT_ERROR)
      })
  },
  [EVENT_OWNED_LIST]: ({ commit }) => {
    commit(EVENT_REQUEST)
    api
      .get(`participant/${store.getters.authId}/eventOwnerOf`, {
        params: {
          filter: {
            where: {
              and: [
                {
                  cancelled: {
                    neq: true
                  }
                }
              ]
            },
            include: [
              {
                relation: 'activity_type'
              },
              {
                relation: 'location',
                scope: {
                  fields: ['name', 'cost', 'city', 'postalCode', 'adress']
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(EVENT_OWNED_LIST_SUCCESS, resp)
        return resp
      })
      .catch((e) => {
        console.error(e)
        commit(EVENT_ERROR)
      })
  },
  async [EVENT_CREATE]({ commit }) {
    commit(EVENT_REQUEST)

    let imageUploadResponse = null
    try {
      // First upload image
      if (state.eventItem.image != null) {
        const adjustBlob = await window.photoRotationAdjust(
          state.eventItem.image
        )
        const formData = new FormData()
        formData.append('file', adjustBlob)
        imageUploadResponse = await api.post(
          `attachments/${config.storageContainer}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        commit(EVENT_CREATE_UPLOAD_SUCCESS, imageUploadResponse)
      }
      // Then post event
      const eventCreateResponse = await api.post(
        'event',
        eventItemFormatter.createItem.format()
      )
      if (eventCreateResponse) {
        commit(EVENT_CREATE_SUCCESS)
        return eventCreateResponse
      }
    } catch (e) {
      commit(EVENT_ERROR)
      return e
    }
  },
  async [EVENT_UPDATE]({ commit }) {
    commit(EVENT_REQUEST)

    let imageUploadResponse = null
    try {
      // First upload image
      if (state.eventUpdateItem.image != null) {
        const adjustBlob = await window.photoRotationAdjust(
          state.eventUpdateItem.image
        )
        const formData = new FormData()
        formData.append('file', adjustBlob)
        imageUploadResponse = await api.post(
          `attachments/${config.storageContainer}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        commit(EVENT_EDIT_UPLOAD_SUCCESS, imageUploadResponse)
      }

      if (state.eventUpdateItem.image === null) {
        // Delete the image from the object since it is not needed
        delete state.eventUpdateItem.image
      }

      await api.patch(
        `event/${state.eventUpdateItem.id}`,
        state.eventUpdateItem
      )
      commit(EVENT_UPDATE_SUCCESS)
      return true
    } catch (e) {
      commit(EVENT_ERROR)
    }
    return false
  },
  [EVENT_SET_FILTER]: ({ commit }, filter) => {
    commit(EVENT_SET_FILTER_SUCCESS, filter)
    commit(EVENT_DESELECT)
  },
  [EVENT_TYPE_SET_FILTER]: ({ commit }, filter) => {
    commit(EVENT_SET_FILTER_SUCCESS, filter)
    // commit(EVENT_DESELECT)
  },
  [EVENT_SELECT]: ({ commit }, eventID) => {
    const rawDateListFiltered = _.filter(
      state.eventUpcomingRawList,
      (item) => parseInt(item.id) === parseInt(eventID)
    )
    let selectedEvent = null
    if (_.size(rawDateListFiltered) > 0) {
      selectedEvent = rawDateListFiltered[0]
    }
    commit(EVENT_SELECT_SUCCESS, selectedEvent)
  },
  [EVENT_EDIT]: ({ commit }, { id, securityHash }) => {
    commit(EVENT_REQUEST)
    return api
      .get('event/' + id, {
        params: {
          securityHash,
          filter: {
            include: [
              {
                relation: 'activity_type'
              },
              {
                relation: 'dates',
                scope: {
                  order: 'date ASC'
                }
              },
              {
                relation: 'location',
                scope: {
                  fields: [
                    'name',
                    'cost',
                    'city',
                    'postalCode',
                    'adress',
                    'coordinates'
                  ]
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(EVENT_EDIT_SUCCESS, resp)
        commit(EVENT_EDIT_REQUEST_COMPLETED, true)
        return resp
      })
      .catch((resp) => {
        commit(EVENT_ERROR, resp)
        commit(EVENT_EDIT_REQUEST_COMPLETED, true)
      })
  },
  [EVENT_VIEW]: ({ commit }, { eventID, securityHash }) => {
    commit(EVENT_REQUEST)
    return api
      .get('event/' + eventID, {
        params: {
          securityHash,
          filter: {
            include: [
              {
                relation: 'activity_type'
              },
              {
                relation: 'location',
                scope: {
                  fields: [
                    'name',
                    'cost',
                    'city',
                    'postalCode',
                    'adress',
                    'coordinates'
                  ]
                }
              },
              {
                relation: 'dates',
                scope: {
                  order: 'date ASC'
                }
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(EVENT_VIEW_SUCCESS, resp)
        commit(EVENT_VIEW_REQUEST_COMPLETED, true)
        return resp
      })
      .catch(() => {
        commit(EVENT_ERROR)
        commit(EVENT_VIEW_REQUEST_COMPLETED, true)
      })
  },
  async [EVENT_DELETE]({ commit }, eventID) {
    commit(EVENT_REQUEST)
    try {
      const eventDeleteResponse = await api.post(`event/${eventID}/cancel`)
      if (eventDeleteResponse) {
        commit(EVENT_DELETE_SUCCESS)
        return true
      }
    } catch (e) {
      commit(EVENT_ERROR)
      return false
    }
  },
  async [EVENT_UPDATE_SINGLE]({ commit }, { eventId, updateObject }) {
    commit(EVENT_REQUEST)
    try {
      const updateSingleResponse = await api.patch(
        `event/${eventId}`,
        updateObject
      )
      if (updateSingleResponse.status === 200) {
        commit(EVENT_UPDATE_SINGLE_SUCCESS)
        return updateSingleResponse.data
      } else {
        commit(EVENT_ERROR)
        return undefined
      }
    } catch (e) {
      commit(EVENT_ERROR)
      return undefined
    }
  },
  async [EVENT_UPDATE_IMAGE]({ commit, dispatch }, { eventId, image }) {
    commit(EVENT_REQUEST, image)
    if (image) {
      try {
        const adjustBlob = await window.photoRotationAdjust(image)
        const formData = new FormData()
        formData.append('file', adjustBlob)
        const updateImageResponse = await api.post(
          `attachments/${config.storageContainer}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (updateImageResponse.status === 200) {
          const updateEvent = await dispatch(EVENT_UPDATE_SINGLE, {
            eventId,
            updateObject: {
              image: {
                container: isDefinedType(
                  updateImageResponse,
                  'data.result.files.file.0.container',
                  ['string', 'value']
                ),
                file: isDefinedType(
                  updateImageResponse,
                  'data.result.files.file.0.name',
                  ['string', 'value']
                )
              }
            }
          })
          if (updateEvent) {
            return true
          } else {
            return false
          }
        } else {
          commit(EVENT_ERROR, 'image not added to event')
          return false
        }
      } catch (e) {
        commit(EVENT_ERROR, e)
        return false
      }
    } else {
      const updateEvent = await dispatch(EVENT_UPDATE_SINGLE, {
        eventId,
        updateObject: { image: '' }
      })
      if (updateEvent) {
        return true
      } else {
        return false
      }
    }
  },
  async [EVENT_LOGGED_IN_USER_ATTENDANCE]({ commit }, { eventId }) {
    commit(EVENT_REQUEST, {
      action: EVENT_LOGGED_IN_USER_ATTENDANCE,
      eventId
    })
    try {
      const resp = await api.get(`event/${eventId}/attendance`)
      if (resp && resp.status === 200) {
        commit(EVENT_LOGGED_IN_USER_ATTENDANCE_SUCCESS, resp.data)
      }
    } catch (err) {
      commit(
        EVENT_ERROR,
        `Something went wrong with EVENT_LOGGED_IN_USER_ATTENDANCE: ${err}`
      )
    }
  },
  async [EVENT_CREATED_BY_PARTICIPANT]({ commit }) {
    commit(EVENT_REQUEST, {
      action: EVENT_CREATED_BY_PARTICIPANT
    })
    try {
      const resp = await api.get(`participant/events_created`)
      if (resp && resp.status === 200) {
        commit(EVENT_CREATED_BY_PARTICIPANT_SUCCESS, resp.data)
      }
    } catch (err) {
      commit(
        EVENT_ERROR,
        `Something went wrong with EVENT_CREATED_BY_PARTICIPANT: ${err}`
      )
    }
  },
  [EVENT_GET_CREDITCARDS]: async ({ commit }, eventId) => {
    commit(EVENT_REQUEST, {
      action: EVENT_GET_CREDITCARDS,
      body: eventId
    })
    try {
      const resp = await api.get(`event/${eventId}/creditcards`, {
        params: {
          filter: {
            where: {
              active: true
            }
          }
        }
      })
      if (resp && resp.status === 200) {
        commit(EVENT_GET_CREDITCARDS_SUCCESS, { eventId, data: resp.data })
      }
    } catch (err) {
      commit(EVENT_ERROR, `Something went wrong: ${err}`)
    }
  },
  [EVENT_UPDATE_ATTENDANCE]: async (
    { commit },
    {
      eventId,
      dateId,
      securityHash,
      dates,
      creditcardId,
      issuer,
      paymentMethod
    }
  ) => {
    commit(EVENT_REQUEST, {
      action: EVENT_UPDATE_ATTENDANCE,
      body: { eventId, dateId, dates, creditcardId }
    })
    try {
      const resp = await api.post(`event/${eventId}/updateAttendance`, {
        dates,
        issuer,
        paymentMethod,
        creditcardId,
        confirmationUrl: `${config.appUrl}/events/${dateId}/${securityHash}`
      })
      if (resp && resp.status === 200) {
        commit(EVENT_UPDATE_ATTENDANCE_SUCCESS)
        return resp.data
      }
      return null
    } catch (err) {
      commit(EVENT_ERROR, `Something went wrong: ${err}`)
      return null
    }
  }
}

const mutations = {
  [EVENT_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [EVENT_ERROR]: (state) => {
    state.status = 'error'
  },
  [EVENT_STATUS_EMPTY]: (state) => {
    Vue.set(state, 'status', '')
  },
  [EVENT_BY_ID_LIST_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'eventUpcomingRawList', resp.eventsWithAttendance)
  },
  [EVENT_SIGNED_UP_LIST_SUCCESS]: (state, resp) => {
    state.status = 'success'
    const eventList = []
    for (const property in resp.data) {
      if (!resp.data.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
      const dateItem = resp.data[property]
      if (dateItem.event !== undefined) {
        const eventItem = dateItem.event
        eventList.push({
          id: eventItem.id,
          name: eventItem.name,
          activityIcon: eventItem.activity_type.icon,
          imageUrl: eventItemFormatter.image(eventItem),
          startTime: DateTime.fromISO(dateItem.startDateTime).toFormat('HH:mm'),
          endTime: DateTime.fromISO(dateItem.endDateTime).toFormat('HH:mm'),
          date: DateTime.fromISO(dateItem.startDateTime).toFormat('ccc d LLL'),
          dateId: dateItem.id,
          locationName: eventItem.location.name,
          locationData: eventItem.location,
          cost: eventItemFormatter.cost(eventItem.cost),
          costFeeIncluded: eventItemFormatter.costFeeIncluded(
            eventItem.cost,
            eventItem.fee
          ),
          maximumParticipants: _.isNumber(eventItem.maximumParticipants)
            ? eventItem.maximumParticipants
            : 0,
          numberOfParticipants: _.size(
            dateItem.participant_dates.filter(
              (item) => item.attendance === 'yes'
            )
          ),
          dateWithinAWeek:
            DateTime.fromISO(dateItem.startDateTime).minus({ days: 7 }) <
            DateTime.local(),
          securityHash: eventItem.securityHash
        })
      }
    }
    Vue.set(state, 'eventSignedUpList', eventList)
  },
  [EVENT_OWNED_LIST_SUCCESS]: (state, resp) => {
    state.status = 'success'
    let eventList = []
    let eventOwnedFuture = []
    let eventOwnedPast = []
    for (const eventItem of resp.data) {
      const nextDate = eventItemFormatter.nextDate(
        eventItem.nextDate,
        eventItem.timezoneOffset
      )
      const dateItem = {
        securityHash: eventItem.securityHash,
        id: eventItem.id,
        name: eventItem.name,
        activityIcon: eventItem.activity_type.icon,
        imageUrl: eventItemFormatter.image(eventItem),
        startTime:
          nextDate.startDateTimeFormatted || eventItem.startTime.substr(0, 5),
        endTime:
          nextDate.endDateTimeFormatted || eventItem.endTime.substr(0, 5),
        date:
          nextDate.dateFormatted ||
          moment(eventItem.startDate).format('dd D MMM'),
        dateId: eventItem.dateId || nextDate.id,
        locationName: eventItem.location && eventItem.location.name,
        locationData: eventItem.location,
        cost: eventItemFormatter.cost(eventItem.cost),
        costFeeIncluded: eventItemFormatter.costFeeIncluded(
          eventItem.cost,
          eventItem.fee
        ),
        maximumParticipants: _.isNumber(eventItem.maximumParticipants)
          ? eventItem.maximumParticipants
          : 0,
        numberOfParticipants: nextDate.participantCount,
        startDateTime: nextDate.startDateTime || eventItem.startDate,
        isEnded: !eventItem.nextDate
      }
      eventList.push(dateItem)
      if (nextDate.dateFormatted) {
        eventOwnedFuture.push(dateItem)
      } else {
        eventOwnedPast.push(dateItem)
      }
    }
    // Sort items
    eventList = _.orderBy(eventList, ['startDateTime'], ['asc'])
    eventOwnedFuture = _.orderBy(eventOwnedFuture, ['startDateTime'], ['asc'])
    eventOwnedPast = _.orderBy(eventOwnedPast, ['startDateTime'], ['desc'])
    Vue.set(state, 'eventOwnedList', eventList)
    Vue.set(state, 'eventOwnedFuture', eventOwnedFuture)
    Vue.set(state, 'eventOwnedPast', eventOwnedPast)
  },
  [EVENT_CONVERT_LIST_ITEM]: (state) => {
    Vue.set(
      state,
      'eventUpcomingList',
      eventItemFormatter.getList(state.eventUpcomingRawList)
    )
  },
  [EVENT_SET_LOCATION_CITY_LIST]: (state) => {
    Vue.set(
      state,
      'locationCityList',
      eventItemFormatter.getCityList(state.eventUpcomingList)
    )
  },
  [EVENT_SET_FILTER_SUCCESS]: (state, filter) => {
    // Save filter to state
    if (!_.isEmpty(filter)) {
      switch (filter.type) {
        case 'location':
          Vue.set(state, 'filterSelectedLocation', filter.value)
          break
        case 'activity':
          Vue.set(state, 'filterSelectedActivity', filter.value)
          break
        case 'day':
          Vue.set(state, 'filterSelectedDay', filter.value)
          break
        case 'eventType': {
          Vue.set(state, 'filterSelectedEventType', filter.value)
        }
      }
    }

    // Update array with selected filter types
    if (filter.type === 'eventType') {
      if (state.filterSelectedEventTypeArray.includes(filter.value)) {
        state.filterSelectedEventTypeArray = state.filterSelectedEventTypeArray.filter(
          (eventType) => eventType !== filter.value
        )
      } else {
        state.filterSelectedEventTypeArray = [
          ...state.filterSelectedEventTypeArray,
          filter.value
        ]
      }
    }
    // Filter list list
    const newEventUpcomingList = []
    for (const property in state.eventUpcomingList) {
      if (!state.eventUpcomingList.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
      const eventItem = state.eventUpcomingList[property]
      const isVisible = eventItemFormatter.isVisible(eventItem)
      state.eventUpcomingList[property].isVisible = isVisible
      newEventUpcomingList.push(state.eventUpcomingList[property])
    }
    Vue.set(state, 'eventUpcomingList', newEventUpcomingList)
  },
  [EVENT_SELECT_SUCCESS]: (state, event) => {
    // Get all available date from dateList
    const dateData = eventItemFormatter.getDateData(event.id)
    // Format some data
    event.imageUrl = eventItemFormatter.image(event)
    event.date = eventItemFormatter.date(dateData.firstDateItem.startDateTime)
    event.cost = eventItemFormatter.cost(dateData.firstDateItem.cost)
    event.startTime = eventItemFormatter.time(
      dateData.firstDateItem.startDateTime
    )
    event.endTime = eventItemFormatter.time(dateData.firstDateItem.endDateTime)
    event.maximumParticipants = _.isNumber(event.maximumParticipants)
      ? event.maximumParticipants
      : 0
    event.numberOfParticipants = _.size(dateData.firstDateItem.participants)
    Vue.set(state, 'selectedEvent', event)
  },
  [EVENT_DESELECT]: (state) => {
    Vue.set(state, 'selectedEvent', null)
  },
  [EVENT_CREATE_SUCCESS]: (state) => {
    state.status = 'success'
    eventItemFormatter.createItem.clearLocal()
  },
  [EVENT_UPDATE_SUCCESS]: (state) => {
    // Update the state and clear the update event item
    state.status = 'success'
    state.eventUpdateItem = null
  },
  [EVENT_CREATE_UPLOAD_SUCCESS]: (state, resp) => {
    state.eventItem.image = {
      container: isDefinedType(resp, 'data.result.files.file.0.container', [
        'string',
        'value'
      ]),
      file: isDefinedType(resp, 'data.result.files.file.0.name', [
        'string',
        'value'
      ])
    }
  },
  [EVENT_EDIT_UPLOAD_SUCCESS]: (state, resp) => {
    state.eventUpdateItem.image = {
      container: isDefinedType(resp, 'data.result.files.file.0.container', [
        'string',
        'value'
      ]),
      file: isDefinedType(resp, 'data.result.files.file.0.name', [
        'string',
        'value'
      ])
    }
  },
  [EVENT_EDIT_SUCCESS]: (state, resp) => {
    const event = resp.data
    Vue.set(state, 'status', 'success')
    Vue.set(state, 'eventEdit', event)
    state.eventEdit.imageUrl = event.imageUrl
      ? `${config.baseUrl}${event.imageUrl}`
      : noImage
  },
  [EVENT_VIEW_SUCCESS]: (state, resp) => {
    const event = resp.data
    // Format some data
    event.imageUrl = eventItemFormatter.image(event)
    event.activityIcon = event.activity_type.icon
    event.cost = eventItemFormatter.cost(event.cost)
    event.costFeeIncluded = eventItemFormatter.costFeeIncluded(
      event.cost,
      event.fee
    )
    event.date = eventItemFormatter.date(event.startDateTime)
    event.maximumParticipants = _.isNumber(event.maximumParticipants)
      ? event.maximumParticipants
      : 0
    event.locationName = event.location.name
    event.url = eventItemFormatter.getUrl(event)
    event.nextDate = eventItemFormatter.nextDate(event.nextDate)
    Vue.set(state, 'eventView', event)
  },
  [EVENT_EDIT_REQUEST_COMPLETED]: (state, isCompleted) => {
    Vue.set(state, 'eventEditRequestCompleted', isCompleted)
  },
  [EVENT_VIEW_REQUEST_COMPLETED]: (state, isCompleted) => {
    Vue.set(state, 'eventViewRequestCompleted', isCompleted)
  },
  [EVENT_DELETE_SUCCESS]: (state) => {
    state.status = 'success'
    Vue.set(state, 'eventView', null)
  },
  [EVENT_ITEM_SET_ACTIVITY]: (state, activityID) => {
    state.eventItem.activityID = activityID
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_ITEM_SET_EVENT_TYPE]: (state, eventTypeID) => {
    state.eventItem.eventTypeID = eventTypeID
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_ITEM_SET_WHEN]: (state, activityData) => {
    state.eventItem.startTime = activityData.startTime
    state.eventItem.endTime = activityData.endTime
    state.eventItem.startDate = activityData.startDate
    state.eventItem.endDate = activityData.endDate
    state.eventItem.recurring = activityData.recurring
    state.eventItem.isRecurring = activityData.isRecurring
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_UPDATE_SET_LOCATION]: (state, locationID) => {
    state.eventEdit.locationID = locationID
  },
  [EVENT_ITEM_SET_LOCATION]: (state, locationID) => {
    state.eventItem.locationID = locationID
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_ITEM_SET_COMMERCIAL_LOCATION]: (state, location) => {
    state.eventItem.locationID = location.id
    state.eventItem.commercialLocation = location
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_ITEM_SET_INFO]: (state, infoData) => {
    state.eventItem.name = infoData.name
    state.eventItem.description = infoData.description
    state.eventItem.gender = infoData.gender
    state.eventItem.maximumParticipants = parseInt(infoData.maximumParticipants)
    state.eventItem.image = infoData.image
    state.eventItem.imageUrl = infoData.imageUrl
    state.eventItem.private = infoData.private
    state.eventItem.phoneNumber = infoData.phoneNumber
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_ITEM_SET_GROUP]: (state, groupId) => {
    state.eventItem.groupId = parseInt(groupId)
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_ITEM_SET_COSTS]: (state, data) => {
    state.eventItem.hasCosts = data.hasCosts
    state.eventItem.cost = !data.cost ? 0 : parseFloat(data.cost)
    state.eventItem.fee = data.fee
    state.eventItem.bankAccountNumber = data.bankAccountNumber
    state.eventItem.bankAccountName = data.bankAccountName
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_ITEM_SET]: (state, data) => {
    console.log(data)
    state.eventItem[data.key] = data.value
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_PARTICIPANT_ATTENDANCE_SUCCESS]: (state, data) => {
    state.status = 'success'
    const dateList = data.participantsWithAttendance
    const updatedDateList = dateList.map((date) => {
      const participants = date.participants.map((participant) => {
        const re = new RegExp('^(http|https)://', 'i')
        const str = participant.avatarUrl
        const match = re.test(str)
        if (!participant.avatarUrl) return participant
        if (match) {
          return participant
        } else {
          participant.avatarUrl = config.baseUrl + participant.avatarUrl
          return participant
        }
      })
      return {
        ...date,
        participants
      }
    })
    Vue.set(state, 'participantsWithAttendanceList', updatedDateList)
  },
  [EVENT_ITEM_UPDATE]: (state, event) => {
    state.eventItem = { ...state.eventItem, ...event }
    eventItemFormatter.createItem.saveToLocal(state.eventItem)
  },
  [EVENT_UPDATE_SINGLE_SUCCESS]: (state) => {
    state.status = 'success'
    state.eventUpdateItem = null
  },
  [EVENT_UPDATE_IMAGE_SUCCESS]: (state) => {
    state.status = 'success'
    state.eventUpdateItem = null
  },
  [EVENT_CREATE_RESET]: () => {
    eventItemFormatter.createItem.clearLocal()
  },
  [EVENT_LOGGED_IN_USER_ATTENDANCE_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.attendance = resp
  },
  [EVENT_CREATED_BY_PARTICIPANT_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.eventsCreatedByParticipant = resp.map((event) => {
      return {
        ...event,
        image:
          event.image && Object.keys(event.image).length
            ? getImageUrl(event.image)
            : null,
        ...(event.nextDate
          ? {
              nextDateFormatted: `${DateTime.fromISO(
                event.nextDate.startDateTime
              ).toFormat('ccc DD T')}`
            }
          : {})
      }
    })
  },
  [EVENT_STORE_DATES_TO_UPDATE]: (state, dates) => {
    state.datesToUpdate = dates
  },
  [EVENT_GET_CREDITCARDS_SUCCESS]: (state, { eventId, data }) => {
    state.loading = false
    state.creditcards = {
      ...state.creditcards,
      [eventId]: data
    }
  },
  [EVENT_UPDATE_ATTENDANCE_SUCCESS]: (state) => {
    state.loading = false
  }
}

// helper functions
const eventItemFormatter = {
  createItem: {
    format: function () {
      const {
        eventItem: {
          activityID,
          commercialLocation,
          cost,
          description,
          endDate,
          endTime,
          eventTypeID,
          groupId,
          image,
          isRecurring,
          locationID,
          maximumParticipants,
          name,
          newGroupName,
          privateEvent,
          publicEvent,
          recurring,
          sendGeneralInvites,
          sendGroupInvites,
          selectedGroupId,
          startDate,
          startTime,
          utcOffset
        }
      } = state
      return {
        activityID,
        commercialLocation,
        cost: !cost ? 0 : parseFloat(cost.toFixed(2)),
        description: description,
        endDate: isRecurring === false || recurring === null ? null : endDate,
        endTime: DateTime.fromISO(endTime)
          .set({ second: 0 })
          .toFormat('HH:mm:ss'),
        event_typeID: eventTypeID,
        gender: 'both',
        groupID: selectedGroupId ? parseInt(selectedGroupId) : groupId,
        image,
        locationID,
        location_typeID: 1,
        maximumParticipants,
        name,
        newGroupName,
        private: privateEvent || !publicEvent,
        recurring: recurring || 'none',
        sendGeneralInvites,
        sendGroupInvites,
        startDate,
        startTime: DateTime.fromISO(startTime)
          .set({ second: 0 })
          .toFormat('HH:mm:ss'),
        utcOffset
      }
    },
    saveToLocal(eventItem) {
      localStorage.setItem('eventItemCreate', JSON.stringify(eventItem))
    },
    clearLocal() {
      state.eventItem = {}
      localStorage.removeItem('eventItemCreate')
    }
  },
  getList: function (rawEventList) {
    const eventUpcomingList = []
    for (const eventItem of rawEventList) {
      // Get all available date from dateList
      const dateData = eventItemFormatter.getDateData(eventItem.id)
      const d = new Date(dateData.firstDateItem.startDateTime)

      if (eventItem.dates.length > 1) {
        const attendanceArr = []
        eventItem.datesWithParticipation.forEach((i) => {
          const attendancePerDate = i.attendance.filter(
            (j) => j.attendance === 'yes'
          )
          attendanceArr.push(attendancePerDate)
        })
        eventItem.dates.forEach((date, index) => {
          eventUpcomingList.push({
            securityHash: eventItem.securityHash,
            rawDateTime: date.startDateTime,
            id: eventItem.id,
            name: eventItem.name,
            eventType: eventItem.eventType,
            eventTypeID: eventItem.event_typeID,
            activityIcon: eventItem.activity_type.icon,
            imageUrl: eventItemFormatter.image(eventItem),
            startTime: eventItemFormatter.time(
              date.startDateTime,
              eventItem.timezoneOffset
            ),
            endTime: eventItemFormatter.time(
              date.endDateTime,
              eventItem.timezoneOffset
            ),
            dateId: date.id,
            date: eventItemFormatter.date(date.startDateTime),
            sortableDate: date.startDateTime,
            locationName: eventItem.location.name,
            locationData: eventItem.location,
            cost: eventItemFormatter.cost(eventItem.cost),
            costFeeIncluded: eventItemFormatter.costFeeIncluded(
              eventItem.cost,
              eventItem.fee
            ),
            maximumParticipants: _.isNumber(eventItem.maximumParticipants)
              ? eventItem.maximumParticipants
              : 0,
            numberOfParticipants: attendanceArr[index].length,
            buttonText: eventItemFormatter.buttonText(
              eventItem.maximumParticipants,
              _.size(dateData.firstDateItem.participants),
              _.size(dateData.dateList)
            ),
            activityID: eventItem.activityID,
            city: eventItem.location.city,
            availableDays: eventItemFormatter.availableDays(dateData.dateList),
            isVisible: true,
            startDateTime: dateData.firstDateItem.startDateTime,
            startDate: new Date(d.getFullYear(), d.getMonth() + 1, d.getDate()),
            userPreferences: store.getters.getParticipantProfile.activities
              ? store.getters.getParticipantProfile.activities
                  .map((pref) => pref.id)
                  .includes(eventItem.activityID)
              : false,
            private: eventItem.private
          })
        })
      } else {
        const hasDates = dateData.dateList.length > 0
        eventUpcomingList.push({
          securityHash: eventItem.securityHash,
          rawDateTime: eventItem.startDate,
          id: eventItem.id,
          name: eventItem.name,
          eventType: eventItem.eventType,
          eventTypeID: eventItem.event_typeID,
          activityIcon: eventItem.activity_type.icon,
          imageUrl: eventItemFormatter.image(eventItem),
          startTime: eventItemFormatter.time(
            hasDates
              ? dateData.firstDateItem.startDateTime
              : eventItem.dates[0].startDateTime,
            eventItem.timezoneOffset
          ),
          endTime: eventItemFormatter.time(
            hasDates
              ? dateData.firstDateItem.endDateTime
              : eventItem.dates[0].endDateTime,
            eventItem.timezoneOffset
          ),
          date: eventItemFormatter.date(dateData.firstDateItem.startDateTime),
          dateId: dateData.firstDateItem.id,
          sortableDate: dateData.firstDateItem.startDateTime,
          locationName: eventItem.location.name,
          locationData: eventItem.location,
          cost: eventItemFormatter.cost(eventItem.cost),
          costFeeIncluded: eventItemFormatter.costFeeIncluded(
            eventItem.cost,
            eventItem.fee
          ),
          maximumParticipants: _.isNumber(eventItem.maximumParticipants)
            ? eventItem.maximumParticipants
            : 0,
          numberOfParticipants: eventItem.participantDateEntries.filter(
            (i) => i.attendance === 'yes'
          ).length,
          buttonText: eventItemFormatter.buttonText(
            eventItem.maximumParticipants,
            _.size(dateData.firstDateItem.participants),
            _.size(dateData.dateList)
          ),
          activityID: eventItem.activityID,
          city: eventItem.location.city,
          availableDays: eventItemFormatter.availableDays(dateData.dateList),
          isVisible: true,
          startDateTime: dateData.firstDateItem.startDateTime,
          startDate: new Date(d.getFullYear(), d.getMonth() + 1, d.getDate()),
          userPreferences: store.getters.getParticipantProfile.activities
            ? store.getters.getParticipantProfile.activities
                .map((pref) => pref.id)
                .includes(eventItem.activityID)
            : false,
          private: eventItem.private
        })
      }
    }
    // eventUpcomingList = _.orderBy(eventUpcomingList, ['startDate', 'userPreferences', 'startDateTime'], ['asc', 'desc', 'asc'])
    return _.orderBy(
      eventUpcomingList,
      ['sortableDate', 'userPreferences', 'startDateTime'],
      ['asc', 'desc', 'asc']
    )
  },
  slugify: function (text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
  },
  getUrl: function (eventItem) {
    let url =
      window.location.protocol +
      '//' +
      window.location.hostname +
      '/event/' +
      eventItem.id +
      '/' +
      eventItem.securityHash +
      '/' +
      this.slugify(eventItem.name)
    if (eventItem.private) {
      url += '?hash=' + eventItem.securityHash
    }
    return url
  },
  getCityList: function (eventUpcomingList) {
    return eventUpcomingList
      .reduce(
        (cities, item) => [
          ...cities,
          ...(cities.map((c) => c.value).includes(item.city)
            ? []
            : [
                {
                  key: item.city,
                  value: item.city
                }
              ])
        ],
        []
      )
      .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0))
  },
  getDateData: function (eventID) {
    const dateData = {
      dateList: [],
      firstDateItem: { date: null, participants: {} }
    }
    const rawDateListFiltered = _.filter(
      store.getters.getDateUpcomingList,
      (item) => parseInt(item.eventID) === parseInt(eventID)
    )
    if (_.size(rawDateListFiltered) && _.size(rawDateListFiltered[0].dates)) {
      dateData.dateList = rawDateListFiltered[0].dates
      dateData.firstDateItem = rawDateListFiltered[0].dates[0]
    }
    return dateData
  },
  isVisible: function (eventItem) {
    let isVisible = true

    // Check on location
    if (!_.isEmpty(state.filterSelectedLocation)) {
      if (
        !_.isEqual(
          eventItem.city.toLowerCase(),
          state.filterSelectedLocation.toLowerCase()
        )
      ) {
        isVisible = false
      }
    }
    // Check on activity
    if (
      state.filterSelectedActivity !== '' &&
      state.filterSelectedActivity > 0
    ) {
      if (
        Number(eventItem.activityID) !== Number(state.filterSelectedActivity)
      ) {
        isVisible = false
      }
    }
    // Check on Day
    if (state.filterSelectedDay !== '') {
      if (
        eventItem.availableDays.indexOf(parseInt(state.filterSelectedDay)) < 0
      ) {
        isVisible = false
      }
    }
    // Check on Event type
    // if (state.filterSelectedEventType !== '') {
    //   if (eventItem.eventTypeID !== state.filterSelectedEventType) {
    //     isVisible = false
    //   }
    // }
    // Check on selected event types
    if (!state.filterSelectedEventTypeArray.includes(eventItem.eventTypeID)) {
      isVisible = false
    }

    // Check on private
    if (eventItem.private) {
      isVisible = false
    }

    return isVisible
  },
  image: function (eventItem) {
    let eventImage = null
    if (eventItem.imageUrl) {
      eventImage = config.baseUrl + eventItem.imageUrl
    } else if (eventItem.activity_type.defaultImageUrl) {
      eventImage = config.baseUrl + eventItem.activity_type.defaultImageUrl
    } else {
      eventImage = noImage
    }
    return eventImage
  },
  time: (timestamp, timezoneOffset) => {
    const eventCreatedInSummer = timezoneOffset === 2
    return moment(timestamp).isDST()
      ? moment(timestamp)
          .subtract(eventCreatedInSummer ? 0 : 1, 'hours')
          .format('HH:mm')
      : moment(timestamp)
          .add(eventCreatedInSummer ? 1 : 0, 'hours')
          .format('HH:mm')
  },
  date: (timestamp) => moment(timestamp).locale('nl').format('dd D MMM'),
  cost: (cost) => (isNaN(cost) || cost === null ? '0' : cost),
  costFeeIncluded: (cost, fee) => {
    const c = isNaN(cost) || cost === null ? '0' : cost
    const f = isNaN(fee) || fee === null ? '0' : fee
    return (parseFloat(c) + parseFloat(f)).toFixed(2)
  },
  nextDate: function (nextDate) {
    if (nextDate !== null) {
      nextDate = Object.assign({}, nextDate)
      nextDate.startDateTimeFormatted = DateTime.fromISO(
        nextDate.startDateTime
      ).toFormat('HH:mm')
      nextDate.endDateTimeFormatted = DateTime.fromISO(
        nextDate.endDateTime
      ).toFormat('HH:mm')
      nextDate.dateFormatted = DateTime.fromISO(
        nextDate.startDateTime
      ).toFormat('ccc d LLL')
    } else {
      nextDate = {
        startDateTimeFormatted: null,
        endDateTimeFormatted: null,
        dateFormatted: null,
        isFull: false,
        participantCount: 0
      }
    }
    return nextDate
  },
  availableDays: function (dateList) {
    const availableDays = []
    for (const property in dateList) {
      if (!dateList.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
      const dateItem = dateList[property]
      const dayNumber = moment.utc(dateItem.startDateTime).locale('nl').day()
      availableDays.push(dayNumber)
    }
    const availableDaysUniq = _.uniq(availableDays)
    return availableDaysUniq
  },
  buttonText: function (
    maximumParticipants,
    numberOfParticipants,
    numberOfDates
  ) {
    let buttonText = null
    const maximumParticipantsCheck = _.isNumber(maximumParticipants)
      ? parseInt(maximumParticipants)
      : 0
    if (numberOfParticipants >= maximumParticipantsCheck && numberOfDates > 1) {
      buttonText = 'Doe mee op een andere dag'
    }
    return buttonText
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
