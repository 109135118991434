import axios from 'axios'
import config from './config'
import { sha256hash } from './hasher'
import { DateTime } from 'luxon'

export default async ({ event, user, url }) => {
  if (process.env.NODE_ENV === 'production') {
    // hash user data
    let emailHash = undefined
    if (user && user.email) {
      emailHash = await sha256hash(user.email)
    }
    let firstNameHash = undefined
    if (user && user.firstName) {
      firstNameHash = await sha256hash(user.firstName.toLowerCase())
    }
    let lastNameHash = undefined
    if (user && user.lastName) {
      lastNameHash = await sha256hash(user.lastName.toLowerCase())
    }
    let cityHash = undefined
    if (user && user.city) {
      cityHash = await sha256hash(user.city.toLowerCase())
    }
    let genderHash = undefined
    if (user && user.gender) {
      const g = user.gender.toLowerCase().charAt(0)
      if (g === 'f' || g === 'm') {
        genderHash = await sha256hash(g)
      }
    }
    let fbc = undefined
    let fbp = undefined
    const cookies = document.cookie.split('; ')
    const fbcPresent = cookies.find((row) => row.startsWith('_fbc='))
    if (fbcPresent) {
      fbc = fbcPresent.split('=')[1]
    }
    const fbpPresent = cookies.find((row) => row.startsWith('_fbp='))
    if (fbpPresent) {
      fbp = fbpPresent.split('=')[1]
    }
    return axios
      .post(
        `https://graph.facebook.com/v13.0/${config.fbPixelId}/events?access_token=${config.fbAccessToken}`,
        {
          data: [
            {
              event_name: event,
              event_time: Math.round(DateTime.utc().ts / 1000),
              event_source_url: url,
              user_data: {
                ...(emailHash ? { em: [emailHash] } : {}),
                ...(firstNameHash ? { fn: firstNameHash } : {}),
                ...(lastNameHash ? { ln: lastNameHash } : {}),
                ...(cityHash ? { ct: cityHash } : {}),
                ...(genderHash ? { ge: genderHash } : {}),
                client_user_agent: window.navigator.userAgent,
                fbc,
                fbp
              },
              action_source: 'website'
            }
          ],
          ...(process.env.NODE_ENV !== 'production'
            ? { test_event_code: 'TEST66789' }
            : {})
        }
      )
      .then((resp) => resp)
      .catch((err) => {
        console.warn('fb event not send: ', err)
      })
  } else {
    return true
  }
}
