export const IDEAL = 'ideal'
export const PAYPAL = 'paypal'
export const CREDITCARD = 'creditcard'
export const PURCHASE_CREDITCARD = 'PURCHASE_CREDITCARD'
export const SINGLE_PAYMENT = 'SINGLE_PAYMENT'
export const REFUND = 'REFUND'
export const CREDIT_PAYMENT = 'CREDIT_PAYMENT'

// don't change, these are DB values
export const CREDITCARD_OWNER_EVENTS = 'owner_events'
export const CREDITCARD_SINGLE_EVENTS = 'single_events'
export const CREDITCARD_GROUP_EVENTS = 'group_events'
export const CREDITCARD_NO_EVENTS = 'no_events'
export const VALIDITY_ONE_YEAR = '1,year'
export const VALIDITY_HALF_YEAR = '6,month'
export const VALIDITY_INDEFINITELY = 'indefinitely'
export const PRICING_PARAM_MEMBER = 'member'
export const PRICING_PARAM_DURATION = 'duration'
export const PRICING_PARAM_COURT = 'court'
export const PRICING_PARAM_CUSTOMER_TYPE = 'customer_type'
export const PRICING_PARAM_TIMESLOT = 'timeslot'

export const VALIDITY_MAPPING = {
  [VALIDITY_ONE_YEAR]: 'one-year',
  [VALIDITY_HALF_YEAR]: 'six-months',
  [VALIDITY_INDEFINITELY]: 'indefinitely'
}
