import { ifAuthenticated } from './guards'
const GroupsAdmin = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsAdmin')
const GroupsAdminLeague = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsAdminLeague')
const GroupsAll = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsAll')
const GroupsCreateOrEdit = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsCreateOrEdit')
const GroupsDetailPage = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsDetailPage')
const GroupsEvents = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsEvents')
const GroupsLeagueDetailPage = () =>
  import(
    /* webpackChunkName: "groups" */ '@/views/groups/GroupsLeagueDetailPage'
  )
const GroupsLeagueCreateOrEdit = () =>
  import(
    /* webpackChunkName: "groups" */ '@/views/groups/GroupsLeagueCreateOrEdit'
  )
const GroupsLeagueMatches = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsLeagueMatches')
const GroupsMembers = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsMembers')
const GroupsOverview = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsOverview')
const GroupsSearch = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsSearch')
const GroupsTeams = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsTeams')
const GroupsTeamCreateOrEdit = () =>
  import(
    /* webpackChunkName: "groups" */ '@/views/groups/GroupsTeamCreateOrEdit'
  )
const GroupsTeamDetailPage = () =>
  import(/* webpackChunkName: "groups" */ '@/views/groups/GroupsTeamDetailPage')
const GroupsCreditcardOverview = () =>
  import(
    /* webpackChunkName: "groups" */ '@/views/groups/creditcard/GroupsCreditcardOverview'
  )
const GroupsCreditcardDetails = () =>
  import(
    /* webpackChunkName: "groups" */ '@/views/groups/creditcard/GroupsCreditcardDetails'
  )

export default [
  {
    path: '/groups',
    name: 'GroupsOverview',
    component: GroupsOverview
  },
  {
    path: '/groups/all',
    name: 'GroupsAll',
    component: GroupsAll
  },
  {
    path: '/groups/new',
    name: 'GroupsCreate',
    component: GroupsCreateOrEdit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/search',
    name: 'GroupsSearch',
    component: GroupsSearch
  },
  {
    path: '/groups/:id',
    name: 'GroupsDetailPage',
    component: GroupsDetailPage
  },
  {
    path: '/groups/:id/events',
    name: 'GroupsEvents',
    component: GroupsEvents
  },
  {
    path: '/groups/:id/teams',
    name: 'GroupsTeams',
    component: GroupsTeams,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/:id/members',
    name: 'GroupsMembers',
    component: GroupsMembers,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/:id/league/:leagueId',
    name: 'GroupsLeagueDetailPage',
    component: GroupsLeagueDetailPage,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/:id/team/new',
    alias: '/groups/admin/:id/team/new',
    name: 'GroupsTeamCreate',
    component: GroupsTeamCreateOrEdit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/:id/team/:teamId',
    name: 'GroupsTeamDetailPage',
    component: GroupsTeamDetailPage,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/:id/team/:teamId/edit',
    alias: '/groups/admin/:id/team/:teamId/edit',
    name: 'GroupsTeamEdit',
    component: GroupsTeamCreateOrEdit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/admin/:id',
    name: 'GroupsAdmin',
    component: GroupsAdmin,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/admin/:id/edit',
    alias: '/groups/:id/edit',
    name: 'GroupsEdit',
    component: GroupsCreateOrEdit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/admin/:id/league/new',
    name: 'GroupsLeagueCreate',
    component: GroupsLeagueCreateOrEdit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/admin/:id/league/:leagueId',
    name: 'GroupsAdminLeague',
    component: GroupsAdminLeague,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/admin/:id/league/:leagueId/edit',
    name: 'GroupsLeagueEdit',
    component: GroupsLeagueCreateOrEdit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/admin/:id/league/:leagueId/matches',
    name: 'GroupsLeagueMatches',
    component: GroupsLeagueMatches,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/:id/credit',
    name: 'GroupsCreditcardOverview',
    component: GroupsCreditcardOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/:id/credit/:creditcardId',
    name: 'GroupsCreditcardDetails',
    component: GroupsCreditcardDetails,
    beforeEnter: ifAuthenticated
  }
]
