export const TEAM_ADD_MEMBERS = 'TEAM_ADD_MEMBERS'
export const TEAM_CREATE = 'TEAM_CREATE'
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS'
export const TEAM_ERROR = 'TEAM_ERROR'
export const TEAM_GET_DETAILS = 'TEAM_GET_DETAILS'
export const TEAM_GET_DETAILS_SUCCESS = 'TEAM_GET_DETAILS_SUCCESS'
export const TEAM_GET_MEMBERS = 'TEAM_GET_MEMBERS'
export const TEAM_GET_MEMBERS_SUCCESS = 'TEAM_GET_MEMBERS_SUCCESS'
export const TEAM_GET_NAMES = 'TEAM_GET_NAMES'
export const TEAM_GET_NAMES_SUCCESS = 'TEAM_GET_NAMES_SUCCESS'
export const TEAM_REQUEST = 'TEAM_REQUEST'
export const TEAM_RESET_STATE = 'TEAM_RESET_STATE'
export const TEAM_UPDATE = 'TEAM_UPDATE'
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS'
