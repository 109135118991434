const ProductDetailPage = () =>
  import(
    /* webpackChunkName: "products" */ '@/views/products/ProductDetailPage'
  )

const ProductCheckoutPage = () =>
  import(
    /* webpackChunkName: "products" */ '@/views/products/ProductCheckoutPage'
  )

export default [
  {
    path: '/products/:uuid',
    name: 'product-detail',
    component: ProductDetailPage
  },
  {
    path: '/products/:uuid/checkout',
    name: 'product-checkout',
    component: ProductCheckoutPage
  }
]
