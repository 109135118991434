import {
  MATCH_ADD,
  MATCH_ADD_SUCCESS,
  MATCH_ERROR,
  MATCH_REQUEST,
  MATCH_UPDATE,
  MATCH_UPDATE_SUCCESS,
  MATCH_RESET_STATE,
  MATCH_SET_SELECTED_DATE
} from '../actions/match'
import api from '@/utils/api'
// import store from '../index'

const state = {
  loading: false,
  error: null,
  addedMatch: null,
  status: null,
  selectedMatchId: null
}

const getters = { selectedMatchId: (state) => state.selectedMatchId }

const actions = {
  [MATCH_ADD]: async ({ commit }, match) => {
    commit(MATCH_REQUEST)
    try {
      const resp = await api.post('match', {
        dateID: match.dateId,
        leagueID: match.leagueId,
        home_id: match.home_id,
        away_id: match.away_id,
        home_score: match.home_score,
        away_score: match.away_score
      })
      if (resp.status === 200 && resp.data) {
        commit(MATCH_ADD_SUCCESS, resp.data)
      } else {
        commit(MATCH_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(MATCH_ERROR, `Something went wrong: ${err}`)
    }
  },
  [MATCH_UPDATE]: async ({ commit }, updatedScores) => {
    commit(MATCH_REQUEST)
    try {
      for await (const matchId of Object.keys(updatedScores)) {
        const resp = await api.patch(`match/${matchId}`, {
          ...(updatedScores[matchId].away_score && {
            away_score: updatedScores[matchId].away_score
          }),
          ...(updatedScores[matchId].home_score && {
            home_score: updatedScores[matchId].home_score
          })
        })
        if (resp.status !== 200) {
          commit(MATCH_ERROR, `Something went wrong: ${resp.statusText}`)
        }
      }
    } catch (err) {
      commit(MATCH_ERROR, `Something went wrong: ${err}`)
    }
    commit(MATCH_UPDATE_SUCCESS)
  }
}

const mutations = {
  [MATCH_REQUEST]: (state) => {
    state.loading = true
  },
  [MATCH_ERROR]: (state, errorMessage) => {
    state.loading = false
    state.error = errorMessage
  },
  [MATCH_ADD_SUCCESS]: (state, resp) => {
    state.loading = false
    state.addedMatch = resp
  },
  [MATCH_UPDATE_SUCCESS]: (state) => {
    state.loading = false
    state.status = 'updated'
  },
  [MATCH_RESET_STATE]: (state) => {
    state.loading = false
    state.error = null
    state.addedMatch = null
    state.status = null
  },
  [MATCH_SET_SELECTED_DATE]: (state, dateId) => {
    state.selectedMatchId = dateId
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
