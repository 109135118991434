export const DATE_REQUEST = 'DATE_REQUEST'
export const DATE_ERROR = 'DATE_ERROR'
export const DATE_UPCOMING_LIST = 'DATE_UPCOMING_LIST'
export const DATE_UPCOMING_LIST_SUCCESS = 'DATE_UPCOMING_LIST_SUCCESS'
export const DATE_ITEM_BY_EVENT_ID = 'DATE_ITEM_BY_EVENT_ID'
export const DATE_ITEM_BY_DATE_ID = 'DATE_ITEM_BY_DATE_ID'
export const DATE_ITEM_BY_EVENT_ID_SUCCESS = 'DATE_ITEM_BY_EVENT_ID_SUCCESS'
export const DATE_LIST_BY_EVENT_ID = 'DATE_LIST_BY_EVENT_ID'
export const DATE_LIST_BY_EVENT_ID_SUCCESS = 'DATE_LIST_BY_EVENT_ID_SUCCESS'
export const DATE_LIST_WITH_PARTICIPANTS = 'DATE_LIST_WITH_PARTICIPANTS'
export const DATE_LIST_WITH_PARTICIPANTS_SUCCESS =
  'DATE_LIST_WITH_PARTICIPANTS_SUCCESS'
export const DATE_UPDATE_ATTENDANCE_SINGLE_ITEM =
  'DATE_UPDATE_ATTENDANCE_SINGLE_ITEM'
// new endpoints moving towards new api
export const EVENT_DATES_GET = 'EVENT_DATES_GET'
export const EVENT_DATES_GET_START = 'EVENT_DATES_GET_START'
export const EVENT_DATES_GET_SUCCESS = 'EVENT_DATES_GET_SUCCESS'
export const EVENT_DATES_GET_ERROR = 'EVENT_DATES_GET_ERROR'
export const EVENT_DATES_GET_PARTICIPANTS = 'EVENT_DATES_GET_PARTICIPANTS'
export const EVENT_DATES_GET_PARTICIPANTS_START =
  'EVENT_DATES_GET_PARTICIPANTS_START'
export const EVENT_DATES_GET_PARTICIPANTS_SUCCESS =
  'EVENT_DATES_GET_PARTICIPANTS_SUCCESS'
export const EVENT_DATES_GET_PARTICIPANTS_ERROR =
  'EVENT_DATES_GET_PARTICIPANTS_ERROR'
export const DATE_NEXT_EVENTS = 'DATE_NEXT_EVENTS'
export const DATE_NEXT_EVENTS_SUCCESS = 'DATE_NEXT_EVENTS_SUCCESS'
export const DATE_UPDATE_DATE = 'DATE_UPDATE_DATE'
export const DATE_UPDATE_DATE_SUCCESS = 'DATE_UPDATE_DATE_SUCCESS'
export const DATE_LOADING = 'DATE_LOADING'
export const DATE_UPCOMING = 'DATE_UPCOMING'
export const DATE_UPCOMING_SUCCESS = 'DATE_UPCOMING_SUCCESS'
export const DATE_UPCOMING_RESET = 'DATE_UPCOMING_RESET'
