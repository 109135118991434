<template>
  <v-dialog :click-to-close="false" />
</template>
<script>
export default {
  name: 'CookieModal',
  data() {
    return {
      cookiesAccept: localStorage.getItem('cookiesAccept') !== null
    }
  },
  mounted() {
    if (this.cookiesAccept === false) {
      this.$modal.show('dialog', {
        title: 'Cookies',
        text:
          'Ook Inviplay gebruikt cookies om goed te functioneren. Wil je precies weten waarvoor? Lees dan onze <a href="/privacy-policy">privacyverklaring</a>',
        buttons: [
          {
            title: 'Akkoord',
            handler: () => {
              this.acceptCookies()
            }
          }
        ]
      })
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookiesAccept', 'true')
      this.$modal.hide('dialog')
    }
  }
}
</script>
